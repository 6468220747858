.modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
}

.modal {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .4);
    overflow: auto;
    z-index: 100;

    &.modal-sm{
        .modal-box {
            max-width: 400px;
        }
    }

    &.modal-green{
        .modal-header {
            background: #95D6A4;
        }
    }

    .modal-box {
        position: relative;
        min-width: 400px;
        max-width: 600px;
        margin: 8% auto;
        background: $color-white;
        border-radius: 10px;
         animation-name: animatetop;
         animation-duration: 0.4s;
         z-index: 100;
    }

    .modal-header {
        padding: 20px;
        background: $color-primary;
        color: $color-white;
        text-align: center;
        border-radius: 10px 10px 0 0;
        position: relative;

        h4{
            margin: 0;
            font-size: rem(24);
            font-weight: 500;
            font-family: $font-secondary;
        }

        .close-modal{
            background: $color-primary;
            color: $color-white;
            text-align: center;
            border-radius: 30px;
            text-transform: uppercase;
            font-size: rem(12);
            font-weight: 600;
            position: absolute;
            top: -40px;
            right: 0;
            padding: 8px 15px;

            &:hover{
                text-decoration: none;
            }
        }
    }

    .modal-body {
        background: $color-white;
        padding: 30px 20px 20px;
        border-radius: 0 0 10px 10px;

        p{
            font-size: rem(16);
            text-align: center;
        }

        .field{
            label{
                color: $color-primary;
                margin: 0;
                text-transform: uppercase;
                font-size: rem(13);
            }

            .control{
                margin-bottom: 20px;
            }
        }

        button{
            margin: 0 auto;
            background: $color-primary;
            border-color: $color-primary;
            display: block;
            padding: 9px 30px;
        }

        .table-responsive{
            background: $color-gray;
            padding: 15px;
        }

        table{
            background: $color-gray;

            th{
                text-transform: uppercase;
                font-size: rem(13);
                color: $color-primary;
                border-bottom: 1px solid #E8E8E8;
                font-weight: 600;
                padding: 7px 5px;
            }

            td{
                text-transform: uppercase;
                font-size: rem(13);
                color: $color-1;
                border-bottom: 1px solid #E8E8E8;
                padding: 7px 5px;
            }
        }
    }

    .close-modal {
        text-align: right;
        cursor: pointer;
    }

    .modal-footer{
        padding: 0 20px 30px;
        margin: 0 auto;
        text-align: center;
        @include flexbox;
        @include justify-content(space-around);

        .action.primary-outline-green {
            font-family: $font-secondary;
            font-size: rem(16);
            font-weight: 400;
            border-radius: 7px;
            background: $color-white;
            color: #95D6A4;
            border: 2px solid #95D6A4;
            padding: 10px 20px;
            line-height: 1.6rem;

           &:hover,
           &:focus {
                color: $color-white;
                background-color: lighten(#95D6A4, 9%);
                border: 2px solid lighten(#95D6A4, 9%);
                text-decoration: none;
           }
        }

        .action.primary-green {
            font-family: $font-secondary;
            font-size: rem(16);
            font-weight: 400;
            border-radius: 7px;
            background: #95D6A4;
            border: 2px solid #95D6A4;
            color: $color-white;
            padding: 10px 20px;
            line-height: 1.6rem;
            text-align: center;

           &:hover,
           &:focus {
                color: $color-white;
                background-color: lighten(#95D6A4, 9%);
                border: 2px solid lighten(#95D6A4, 9%);
                text-decoration: none;
           }
        }
    }
}

/* Animation */
@-webkit-keyframes animatetop {
    0% {
        top: -50%;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes animatetop {
    0% {
        top: -50%;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

.modal-popup {
    &.popup-authentication {
        .block-authentication{
            border: none;

            .block-title{
                font-weight: 500;
                font-size: 23px;
                line-height: 34px;
                text-transform: uppercase;
                color: $color-primary;
                font-family: $font-secondary;
                border: none;
                position: relative;
                margin-bottom: 1.5rem;

                @media (max-width: 768px) {
                    margin-bottom: 1rem;
                }

                strong{
                    font-weight: 500;
                }
            }

            .block-content{
                p{
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 5px;

                    @media (max-width: 768px) {
                        font-size: rem(14);
                    }
                }

                ul{
                    margin: 0;
                    padding: 0;

                    li{
                        margin-bottom: 5px;
                        font-size: rem(16);
                        list-style: disc inside;

                        @media (max-width: 768px) {
                            font-size: rem(14);
                        }
                    }
                }
            }

            .fieldset.login{
                .actions-toolbar {
                    >.primary {
                        margin-top: 3rem;

                        @media (max-width: 768px) {
                            margin-top: 1.5rem;
                        }
                    }
                }
            }

            .actions-toolbar{
                margin: 0 auto;

                >.primary {
                    margin-top: 4.5rem;

                    @media (max-width: 768px) {
                        margin-top: 1.5rem;
                    }

                    .action{
                        font-size: rem(16);
                        font-weight: 500;
                        border-radius: 7px;
                        background: $color-primary;
                        border: 2px solid $color-primary;
                        padding: 6px 20px;
                        line-height: 1.6rem;
                    }
                }

                > .secondary {
                    float: none;
                    margin-right: 0;
                    padding-top: 0;
                    position: absolute;
                    left: 40px;

                    @media (max-width: 768px) {
                        position: relative;
                        left: 0;
                    }

                    a{
                        text-transform: uppercase;
                        font-size: 0.8125rem;
                        color: $color-primary;
                    }
                }
            }
        }

        .block[class]+.block{
            @media (max-width: 768px) {
                margin-top: 25px;
                padding-top: 25px;
            }

            &:before{
                display: none;
            }
        }
    }

    .modal-content{
        @media (max-width: 768px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    &.modal-slide {
        @media only screen and (max-width: 767px){
            left: 15px;
            right: 15px;
            top: 15px;
            bottom: 15px;
        }
    }

    &.custom-popup-modal{
        .modal-inner-wrap{
            max-width: 600px;
            width: 100%;
            border-radius: 10px;

            .modal-footer{
                display: none;
            }

            .modal-content{
                padding: 0rem 2rem 1rem;
                text-align: center;

                @media only screen and (max-width: 767px){
                    padding: 0rem 1rem 1rem;
                }

                h4{
                    color: $color-primary;
                    font-weight: 500;
                    font-size: 35px;
                    line-height: 40px;
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}
