.page-header {
    background:$color-white;
    color: $color-secondary;
    margin: 0;
    border: none;
    -webkit-transition: all 0.5s ease;
    -moz-transition: position 10s;
    -ms-transition: position 10s;
    -o-transition: position 10s;
    transition: all 0.5s ease;

    &.header-fix{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        animation: smoothScroll 0.5s;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);

        .panel{
            &.wrapper{
                display: none;
            }
        }

        .header.content{
            padding: 15px 20px 15px;
        }

        @media (max-width: 768px){
            .header .links{
                display: none;
            }

            .store-dropdown{
                display: none;
            }

            .block-search{
                display: none;
            }

            .btn-results-mobile{
                display: none!important;
            }
        }
    }

    @keyframes smoothScroll {
        0% {
            transform: translateY(-50px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    .panel {
        @media (max-width: 768px) {

        }

        &.wrapper {
            background: $color-primary;
            border: none;

            @media (max-width: 768px) {
                display: none;
            }
        }

        &.header{
            padding-bottom: 10px;
            padding-top: 10px;

            @media (max-width: 768px) {
                padding-bottom: 7px;
                padding-top: 7px;
            }

            .header-top-bar{
                @include flexbox;
                @include justify-content(space-between);
                @include align-items(center);
                color: $color-white;

                .header-info{
                    p{
                        font-size: rem(14);
                        font-family: $font-secondary;
                        letter-spacing: 0.03em;
                        margin: 0;
                        text-transform: uppercase;

                        @media (max-width: 768px) {
                            font-size: rem(12);
                            line-height: 14px;
                        }
                    }
                }

                .header-buttons{
                    margin-left: auto;

                    .btn{
                        border: 2px solid $color-white;
                        border-radius: 7px;
                        color: $color-white;
                        font-weight: 500;
                        font-size: rem(12);
                        line-height: 14px;
                        font-family: $font-secondary;
                        height: 31px;
                        line-height: 31px;
                        padding: 0 20px;
                        display: inline-block;
                        margin-left: 20px;

                        @media (max-width: 768px) {
                            &-result{
                                display: none;
                            }
                        }

                        &-whatsapp{
                            position: relative;

                            &:before{
                                content: "";
                                background: url($img-path + "icons/icon-whatsapp-white.svg") no-repeat 0 0;
                                background-size: contain;
                                display: inline-block;
                                width: 17px;
                                height: 17px;
                                margin-right: 8px;
                                position: relative;
                                top: 4px;

                                @media (max-width: 768px) {
                                    margin-right: 5px;
                                }
                            }

                            @media (max-width: 768px) {
                               border: none;
                               padding: 0;
                               margin: 0 0 0 5px;
                               width: 85px;
                            }
                        }

                        &:hover{
                            background: $color-white;
                            color: $color-primary;
                            text-decoration: none;

                            &:before{
                                background: url($img-path + "icons/icon-whatsapp.svg") no-repeat 0 0;
                            }
                        }
                    }
                }
            }
        }
    }


    .header {
        .header-menu{
            float: right;
            margin-top: 1.7rem;
            margin-left: 20px;

            @media (max-width: 768px) {
                position: absolute;
                top: 10px;
                margin: 0;
                float: none;
            }
        }

        .logo {
            margin: 15px 0 0;
            max-width: 195px;

            @media (max-width: 768px) {
                margin: 0 auto;
                float: none;
                position: absolute;
                text-align: center;
                left: 0;
                right: 0;

                img{
                    height:  auto;
                    max-width: 127px;
                    margin: 0 auto;
                }
            }
        }

        &.content {
            padding: 26px 20px 26px;

            @media (max-width: 768px) {
                padding: 15px 20px;
            }
        }

        .links {
            float: right;
            margin: 37px 0 0;
            padding: 0 0 0 20px;
            position: relative;

            @media (max-width: 768px) {
                display: none;
            }

            li {
                display: inline-block;
                font-size: rem(13);
                margin: 0;

                &.greet{
                    position: absolute;
                    top: -29px;
                    left: 19px;
                    color: $color-4;
                    font-weight: 500;
                    font-size: rem(12);
                    line-height: 14px;
                    font-family: $font-secondary;
                    width: 280px;

                    .not-logged-in{
                        display: none;
                    }

                    .logged-in{
                        display: block;
                    }
                }

                &.authorization-link{
                    color: $color-primary;

                    a{
                        color: $color-4;

                        span{
                            &.log-out{
                                color: $color-4;
                                border: 2px solid $color-4;
                                border-radius: 7px;
                                font-weight: 500;
                                font-size: rem(12);
                                line-height: 14px;
                                font-family: $font-secondary;
                                height: 35px;
                                line-height: 35px;
                                padding: 0 20px;
                                display: block;
                            }
                        }

                        &:hover{
                            span{
                                &.log-out{
                                    background: $color-4;
                                    color: $color-white;
                                }
                            }
                        }
                    }
                }

                &.customer-welcome{
                    margin: 0 10px 0 0px;

                    .my-account-link{
                        a{
                            color: $color-4;
                            border: 2px solid $color-4;
                            border-radius: 7px;
                            font-weight: 500;
                            font-size: rem(12);
                            line-height: 14px;
                            font-family: $font-secondary;
                            height: 35px;
                            line-height: 35px;
                            padding: 0 20px;
                            display: block;

                            &:hover{
                                background: $color-4;
                                color: $color-white;
                                text-decoration: none;
                            }

                            @media (max-width: 768px){
                                height: 31px;
                                line-height: 31px;
                            }
                        }
                    }
                }

                a {
                    color: $color-secondary;
                    font-size: rem(13);
                }
            }
        }

        .minicart-wrapper{
            margin-left: 20px;
            margin-top: 46px;
            color: $color-secondary;
            position: relative;

            @media (max-width: 768px){
                margin-left: 0;
                margin-top: 7px;
            }

            .action.showcart{
                position: relative;

                &:before{
                    content: "";
                    background-image: get-icon('cart', $color-primary);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 25px;
                    height: 25px;
                }

                .title-text{
                    color: $color-primary;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    font-family: $font-secondary;
                    padding-left: 5px;

                    @media (max-width: 768px) {
                        display: none;
                    }
                }

                .counter{
                    &.qty{
                        background: $color-primary;
                        border-radius: 100%;
                        height: 19px;
                        line-height: 19px;
                        display: inline-block;
                        margin: 2px 0 0 -15px;
                        min-width: 16px;
                        padding: 0 2px;
                        text-align: center;
                        position: absolute;
                        top: -11px;

                        .counter-number{
                            font-size: rem(12);
                            color: $color-white;
                        }
                    }
                }
            }

            .block-minicart {
                margin-top: 17px;
                border-radius: 10px;
                border: none;
                font-size: rem(14);
                padding: 0 20px 15px;
                width: 350px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

                @media (max-width: 768px) {
                    width: 280px;
                }

                &:before, &:after{
                    display: none;
                }

                .block-title{
                    display: block;
                    color: $color-primary;
                    font-size: rem(18);
                    font-weight: 500;
                    font-family: $font-secondary;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
                    padding: 20px;
                    margin: 0 -20px;

                    strong{
                        font-weight: 500;
                    }

                    &:after {
                        content: "";
                        background: $color-primary;
                        width: 23px;
                        height: 3px;
                        display: block;
                        margin: 1px 0 0;
                    }
                }

                .items-total{
                    display: none;
                }

                .subtitle.empty{
                    color: $color-secondary;
                }

                .subtotal{
                    border-bottom: 1px solid $color-1;
                    margin: 0;
                    padding-bottom: 5px;

                    .amount{
                        .price-wrapper{
                            .price{
                                color: $color-primary;
                                font-size: rem(14);
                                display: block;
                            }
                        }
                    }
                }

                .actions{
                    @media (max-width: 768px){
                        float: right;
                        margin: -24px 0 0;
                        text-align: right;
                    }

                    .primary{
                       .action{
                            &.primary{
                                font-size: rem(16);
                                padding: 9px 10px;
                                line-height: 20px;
                                max-width: 235px;
                                margin: 0 auto;
                            }

                            &.edit{
                                display: none;
                            }
                        }
                    }

                    .secondary{
                        a.action{
                            color: $color-primary;
                        }
                    }
                }

               .minicart-items{
                    .product-item{
                        &:not(:first-child){
                            border-top: 1px solid $color-1;
                        }

                        .product-item-details{
                            font-size: rem(14);
                            padding: 0;

                            .product-item-name a{
                                color: $color-primary;
                                font-size: rem(16);
                                font-family: $font-secondary;
                            }

                            .item-qty{
                                border: 2px solid #7D7D7D;
                                border-radius: 7px;
                                height: 30px;
                            }

                            .price-container{
                                .price-excluding-tax{
                                    margin: 0;
                                }

                                .minicart-price{
                                    .price{
                                        color: $color-secondary;
                                        font-size: rem(14);
                                        font-weight: 400;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
               }

               .minicart-items-wrapper{
                    padding: 15px 20px 0;
                    border: none;
                }
            }
        }

        .store-dropdown{
            float: right;
            margin-top: 19px;
            max-width: 120px;
            padding-left: 25px;

            @media (max-width: 768px) {
                margin: 0;
                padding: 0;
                float: left;
                max-width: 53%;
                padding: 0;
                clear: both;
            }

            label{
                font-size: 10px;
                line-height: 11px;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                font-family: $font-secondary;
                background: $color-white;
                color: $color-primary;
                margin: 0 8px 0;
                display: inline-block;
                position: relative;
                top: 8px;
                padding: 0 5px;
            }

            select{
                border: 1px solid #D1D1D1;
                box-sizing: border-box;
                border-radius: 5px;
                background-color: $color-white;
                color: $color-secondary;
                height: 35px;
                font-size: 12px;
                padding: 0 5px;
            }
        }

        .block-search{
            max-width: 410px;
            float: right;
            padding: 0;
            clear: inherit;

            @media (max-width: 768px) {
                max-width: 100%;
                float: none;
                padding: 0;
                clear: both;
                margin: 0;
                padding-top: 10px;
            }

            input{
                background: $color-gray;
            }
        }

        .wrap{
            .btn-results-mobile{
                display: none;
            }

            @media (max-width: 768px) {
                @include flexbox;
                @include justify-content(space-between);
                @include align-items(flex-end);
                margin-top: 2rem;

                .btn-results-mobile{
                    display: block;
                    max-width: 42%;
                    float: right;
                    width: 100%;

                    .btn{
                        padding: 4px 10px;
                        font-size: rem(12);
                        line-height: 1.5rem;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.nav-toggle{
    &:before{
        color: $color-primary;
        font-size: 39px;
        line-height: 39px;

        @media (max-width: 768px) {
            color: $color-primary;
            font-size: 30px;
            line-height: 30px;
        }
    }

    &:hover, &:focus{
        &:before{

            @media (max-width: 768px) {
                color: $color-primary;
            }
        }
    }

    &:after{
        content: "Menu";
        display: block;
        color: #CE151A;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        position: absolute;
        top: 0;
        font-family: $font-secondary;
        margin: 0 auto;
        left: 0;
        right: 0;
    }

}

@media (min-width: 768px){
    .catalog-product-view{
        .page-header {
            &.header-fix + .breadcrumbs{
                margin-top: 15rem;
            }
        }
    }

    .page-layout-2columns-left, .cms-page-view.page-layout-1column{
        .page-header {
            &.header-fix + .sections + .breadcrumbs + #maincontent{
                margin-top: 13rem;
            }
        }
    }
}
