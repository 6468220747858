.contact-container {
    margin-top: 3rem;

    .contact-block {
        background: $color-white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        padding: 20px 30px 30px;
        width: 100%;
        max-width: 415px;
        margin: 0 auto;

        @media (max-width: 768px) {
            padding: 30px;
            border-left: none;
            width: auto;
            min-height: auto;
            margin-bottom: 20px;
        }

        p {
            strong {
                color: $color-secondary;
                text-transform: uppercase;
                font-size: rem(18);
            }
        }

        .fieldset {
            margin: 0 0 20px;

            .control {
                width: 100%;
            }

            > div {
                > .field {
                    > .label{
                        padding: 0px 5px;
                        text-align: left;
                        width: auto;
                        box-sizing: border-box;
                        float: none;
                        text-transform: uppercase;
                        font-weight: 400;
                        font-size: rem(12);
                        color: $color-primary;
                        display: inline-block;
                        font-family: $font-secondary;
                        background-color: $color-white;
                        position: relative;
                        top: 8px;
                        margin: 0 10px;
                        z-index: 3;
                    }
                }
            }

            .field.choice {
                width: 100%;
                min-height: auto;
                margin: 15px 0 5px;
                clear: both;

                label{
                    font-size: 0.8125rem;
                    line-height: 20px;
                    color: #7D7D7D;
                    text-transform: uppercase;
                }
            }

            .field{
                margin: 0 0 10px;
            }
        }

        .actions-toolbar {
            .primary {
                float: right;
                margin: 0 auto;
                text-align: center;
                display: block;
            }
        }
    }
}
