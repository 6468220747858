.checkout-index-index {
    .page-header {
        .header {
            .logo {
                margin: 5px auto;
                max-width: 190px;
                float: none;
                text-align: center;
                width: 100%;

                img{
                    margin: 0 auto;
                    width: 100%;
                    max-width: 190px;
                }
            }
        }
    }

   .checkout-container{
       .opc-wrapper{
            background: $color-white;
            margin-bottom: 35px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 30px;
            width: 65%;
            position: relative;

           .opc{
               .step-title{
                    font-weight: 500;
                    font-size: rem(25);
                    line-height: 30px;
                    font-family: $font-secondary;
                    color: $color-primary;
                    border: none;
                    padding: 0;
                    text-transform: uppercase;
                    margin-bottom: 1rem;
                }

                .fieldset {
                    .legend{
                        display: none;

                        + br{
                            display: none;
                        }
                    }

                    > .field:not(.choice) > .label {
                        padding: 6px 15px 0 0;
                        text-align: left;
                        width: auto;
                        box-sizing: border-box;
                        float: none;
                    }
                }

                .table-checkout-shipping-method {
                    min-width: auto;
                }
           }

           .form-discount {
                max-width: 310px;
            }
       }

       .opc-sidebar{
           margin: 0 0 20px;

            .opc-block-summary {
                background: $color-white;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                padding: 30px;
                margin: 0 0 20px;

                .title{
                    color: $color-primary;
                    font-weight: 500;
                    font-size: rem(25);
                    line-height: 30px;
                    font-family: $font-secondary;
                    margin: 0 0 1rem;
                    padding: 5px 0 0;
                    border: none;
                    text-transform: uppercase;
                }

                .items-in-cart {
                    margin: 15px 0 0;
                    border-top: 1px solid $color-1;

                    .title{
                        font-size: rem(14);
                        display: none;
                    }
                }

                .table-totals{
                    th, td{
                        font-size: rem(14);
                        text-transform: uppercase;
                        font-family: $font-secondary;
                        padding: 5px;
                    }

                    tr{
                        &.grand.totals{
                            th, td{
                                color: $color-primary;
                                font-size: rem(16);
                            }
                        }
                    }

                    th{
                        .title{
                            font-size: rem(14);
                        }
                    }
                }
            }

            .opc-block-shipping-information{
                padding: 0 25px;

                .shipping-information-title{
                    color: $color-primary;
                    font-family: $font-secondary;
                    font-weight: 700;
                    font-size: rem(20);
                    line-height: 25px;
                    margin: 0 0 15px;
                    padding: 0;
                    border: none;
                }
            }

            .minicart-items {
                display: block!important;

                .minicart-items-wrapper{
                    padding: 0;
                    margin: 0;

                    .product-item-details{
                        padding-left: 0;
                    }
                }

                .product-item-details{
                    .product-item-name {
                        font-weight: 400;
                        margin: 0 0 10px;
                        font-size: rem(16);
                        color: $color-primary;
                        font-family: $font-secondary;
                    }

                    .details-qty{
                        font-size: rem(14);
                    }

                    .price{
                        font-size: rem(14);
                    }
                }
            }
        }

        .checkout-payment-method{
            .payment-method-title{
                padding: 10px 0;
            }

            .payment-method {
                + .payment-method {
                    .payment-method-title{
                        border: none;
                    }
                }
            }

            .opc-payment-additional {
                &.discount-code{
                    .payment-option-title{
                        padding: 20px 0;
                        border-top: 1px solid $color-1;

                        .action-toggle{
                            color: $color-primary;
                        }
                    }
                }
            }

            .checkout-billing-address{
                border-top: 1px solid $color-1;
                margin: 20px 0;
                padding-top: 10px;

                .billing-address-details{
                    padding: 0;
                    font-size: rem(14);
                }
            }

            .payment-option-content {
                padding: 0;
            }

            .payment-method._active{
                .payment-method-content{
                    padding: 0 0 20px 22px;
                }
            }

            .payment-method-content{
                padding: 0;

                p{
                    margin: 0;
                }

                .actions-toolbar {
                    position: absolute;
                    bottom: 30px;
                    right: 30px;

                    .action.primary {
                        margin: 0;
                        font-size: rem(16);
                        padding: 6px 20px;
                        line-height: 1.6rem;

                        &:before {
                            content: "";
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            vertical-align: middle;
                            background-image: url($img-path + "icons/icon-check-white.svg");
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: contain;
                            margin-right: 5px;
                        }

                    }
                }
            }
        }
    }
}

.opc-progress-bar{
    margin: 3rem auto 20px;
    text-align: center;

    .opc-progress-bar-item{
        margin: 0 13px;
        width: 140px;

        &:before{
            background: $color-7;
            width: 48px;
            height: 5px;
            left: -37px;
            border-radius: 10px;
            top: 25px;
        }

        &._active, &._complete{
            &:before{
                background: $color-primary;
                height: 5px;
            }

            > span{
                font-size: rem(18);
                color: $color-primary;
                font-family: $font-secondary;
                font-weight: 400;
                display: block;
                text-align: center;

                &:before{
                    background: $color-3;

                }
            }

            &:nth-child(1){
                > span{
                    &:after{
                        background-image: url($img-path + "icons/icon-revisao-red.svg");
                    }
                }
            }

            &:nth-child(2){
                > span{
                    &:after{
                        background-image: url($img-path + "icons/icon-money-red.svg");
                    }
                }
            }

            &:nth-child(3){
                > span{
                    &:after{
                        background-image: url($img-path + "icons/icon-confirmation-red.svg");
                    }
                }
            }
        }

        > span{
            font-size: rem(16);
            color: $color-secondary;
            font-family: $font-secondary;
            font-weight: 400;
            display: block;
            text-align: center;
            padding-top: 85px;
            text-transform: uppercase;

            &:before{
                background: #f5f5f5;
                height: 90px;
                margin: -24px auto;
                width: 90px;
                text-align: center;
                left: 0;
                right: 0;
            }

            &:after{
                font-size: 0;
                content:"";
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                height: 50px;
                width: 50px;
                margin: 0 auto;
                left: 0;
                right: 0;
                top: -4px;
                border-radius: 0;
            }
        }

        &:nth-child(1){
            > span{
                &:after{
                    background-image: url($img-path + "icons/icon-revisao.svg");
                }
            }
        }

        &:nth-child(2){
            > span{
                &:after{
                    background-image: url($img-path + "icons/icon-confirmation.svg");
                }
            }
        }

        &:nth-child(3){
            > span{
                &:after{
                    background-image: url($img-path + "icons/icon-confirmation.svg");
                }
            }
        }
    }
}

.checkout-onepage-success{
    .page-title-wrapper {
        margin: 2rem auto 0;
        display: none;
    }

    .page-main{
        padding-top: 1rem;
    }

    .column.main{
        position: relative;
    }

    .checkout-success{
        margin-top: 2rem;

        h2{
            color: $color-primary;
            font-size: rem(18);
            font-weight: 700;
            margin: 0 0 15px;
            text-transform: uppercase;

            a{
                color: #7D7D7D;
            }
        }

        .order-title{
            display: none;
        }
    }

    .print{
        font-family: $font-secondary;
        font-size: rem(16);
        font-weight: 400;
        border-radius: 7px;
        background: $color-primary;
        padding: 6px 20px;
        line-height: 1.6rem;
        color: $color-white;
        float: none;
        position: absolute;
        right: 30px;
        text-align: center;
        margin: 0;
        top: 180px;

        &:hover, &:focus {
            color: $color-white;
            background-color: lighten($color-primary, 13%);
            text-decoration: none;
        }
    }

    .success-messages{
        background: $color-white;
        padding: 35px 30px 45px;
        margin-bottom: 2rem;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }

    .order-details{
        background: $color-white;
        padding: 35px 30px;
        border-radius: 7px;
        margin-bottom: 2rem;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

        .block-order-details-view{
            .block-title{
                text-transform: uppercase;
                color: $color-primary;
                font-size: rem(16);
                font-weight: 700;
                font-family: $font-secondary;
                margin-bottom: 1rem;
                display: none;
            }

            .block-content{
                font-size: rem(14);
                @include flexbox;

                .box-title{
                    text-transform: uppercase;
                    color: $color-primary;
                    font-size: rem(14);
                    font-weight: 400;
                    font-family: $font-secondary;
                    margin-bottom: 0.5rem;
                    display: block;
                }

                .box{
                    margin-bottom: 20px;
                    border-left: 5px solid #E8E8E8;
                    padding-right: 15px;
                    padding-left: 15px;
                    margin-right: 15px;
                }

            }
        }
    }

    .order-status-info{
        background: $color-white;
        padding: 35px 30px;
        border-radius: 10px;
        margin-bottom: 2rem;
        font-size: rem(16);
        font-weight: 700;
        color: $color-primary;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

        span{
            color: $color-primary;
            font-size: rem(20);
            font-weight: 400;
            font-family: $font-secondary;
            margin-right: 30px;
        }
    }

    .order-date{
        padding: 0 30px;
        font-family: $font-secondary;
        font-size: rem(14);
        margin-top: -70px;
        margin-bottom: 45px;

        span{
            text-transform: uppercase;
            color: $color-primary;
            font-size: rem(13);
            font-weight: 700;
            font-family: $font-secondary;
        }
    }

    .order-details-items{
        border-bottom: none;
    }

    .table-order-items{
        th{
            text-transform: uppercase;
            color: $color-primary;
            font-size: rem(14);
            font-weight: 700;
            font-family: $font-secondary;
        }

        td{
            font-size: rem(14);
            font-family: $font-secondary;

            &.col{
                &.price{
                    span{
                        color: #7D7D7D;
                        font-size: rem(16);
                        font-weight: 700;
                    }
                }

                &.subtotal{
                    span{
                        color: $color-primary;
                        font-size: rem(16);
                        font-weight: 700;
                    }
                }
            }
        }

        tr{
            &.subtotal{
                font-weight: 700;

                th,td{
                    color: #7D7D7D;
                    font-weight: 400;
                }
            }

            &.shipping{
                th,td{
                    color: #7D7D7D;
                    font-weight: 400;
                }
            }

            &.grand_total{
                th,td{
                    color: $color-primary;
                }
            }
        }
    }
}

.opc-wrapper .shipping-address-item.selected-item {
    border: none;
    padding: 20px 0 5px;

    &:after{
        display: none;
    }
}

.minicart-items .product .toggle {
    font-size: 13px;
}

.minicart-items .product-item {
    padding: 10px 0;
}

.opc-block-summary .items-in-cart .product .item-options dt, .opc-block-summary .items-in-cart .product .item-options dd{
    font-size: 13px;
}

.opc-block-summary .items-in-cart .product .item-options{
    margin: 0;
}

.checkout-payment-method .payment-method-content .fieldset>.field {
    margin: 0;
}

.checkout-payment-method .ccard .captcha .input-text, .checkout-payment-method .ccard .number .input-text {
    width: 100%;
    padding: 0 10px;
}

.payment-method #rm_pagseguro_ccflags{
    margin-top: 1rem!important;
}

.checkout-payment-method .input-text.ps_cc_birthdate_year, #rm_pagseguro_cc_cc_owner_birthday_year {
    width: 70px;
}

.opc-block-summary{
    .table.table-totals{
        .totals.shipping{
            .label{
                display: none;
            }
        }
    }
}

.cart-totals{
    .table.totals{
        .totals.shipping{
            .label{
                display: none;
            }
        }
    }
}
