.page-layout-2columns-left,
.page-layout-1column:not(.catalog-product-view) {
    .page-title-wrapper {
        margin: 0rem auto 3rem;
        text-align: center;

        @media (max-width: 991px) {
            margin-bottom: 2rem;
        }

        .page-title {
            margin-bottom: 0;
            color: $color-primary;
        }
    }
}

.page-layout-1column:not(.catalog-product-view) {
    .page-title-wrapper {
        margin: 0rem auto 2rem;
    }
}

.column.main {
    .block {
        &.related{
            .title {
                position: relative;
                margin-bottom: 40px;

                strong {
                    font-weight: 500;
                    font-size: rem(24);
                    line-height: 30px;
                    color: $color-primary;
                    font-family: $font-secondary;
                }
            }
        }
    }
}

.account.page-layout-2columns-left{
    .page-title-wrapper {
        text-align: left;
        margin: 0 0 1rem;

        &:after {
            content: "";
            background: $color-primary;
            position: relative;
            left: 0;
            height: 5px;
            width: 30px;
            border-radius: 10px;
            display: block;
            margin-top: 2px;
        }
    }
}
