.block-image-text{
    overflow: hidden;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
    }

    &.image-left{
        img{
            float: left;
            margin-right: 30px;

            @media (max-width: 768px) {
                float: none;
                margin: 0 auto 1rem;
                display: block;
            }
        }
    }

    &.image-right{
        img{
            float: right;
            margin-left: 30px;

            @media (max-width: 768px) {
                float: none;
                margin: 0 auto 1rem;
                display: block;
            }
        }
    }

}
