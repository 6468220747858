@media (min-width: 768px){
    .page-layout-2columns-left {
        .column.main {
            width: 75.333333%;
            padding-left: 1%;
        }

        .sidebar-main,
        .sidebar-additional {
            width: 24.666667%;
        }
    }
}

.columns {
    .column.main {
        padding-bottom: 4rem;
    }
}

.cms-page-view, .catalog-category-view{
    .page-main{
        ul{
            padding: 0;

            li{
                list-style: disc inside;
                margin-bottom: 0.5rem;
            }
        }

        .row{
            clear: both;
            @include flexbox;
            flex-wrap: wrap;
            margin: 0 -15px;

            .col-4{
                flex: 0 0 auto;
                width: 30.7%;
                padding-right: 15px;
                padding-left: 15px;
                margin-bottom: 2rem;

                h4{
                    color: $color-primary;
                    margin-bottom: 10px;
                }
            }

            .col-6{
                flex: 0 0 auto;
                width: 47%;
                padding-right: 15px;
                padding-left: 15px;
                margin-bottom: 2rem;
            }

            .boxed{
                border-radius: 8px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                padding: 15px;
                min-height: 100px;

                p{
                    margin: 0;
                }
            }
        }

        .block-full{
            clear: both;
            width: 100%;
            margin: 2rem 0;
        }
    }
}
