/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $color-secondary;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="file"],
select,
textarea {
    border: 1px solid $color-10;
    background: transparent;
    font-size: rem(14);
    color: $color-secondary;
    font-family: $font-primary;
    padding: 0 9px;
    border-radius: 5px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;

    &:focus {
        box-shadow: none;
        border-color: $color-primary;
        outline: none;
    }

    &::placeholder {
        opacity: 1;
        color: $color-secondary;
    }

    &.mage-error {
        border-color: $color-error;
        color: $color-error;
    }
}

input[type="file"]{
    width: 100%;
}

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
    box-shadow: none;
}

textarea {
    resize: none;
    height: auto;
    min-height: 150px;
}

/****
** Fieldset
****/

.fieldset {
    letter-spacing: 0;

    >legend.legend {
        margin-left: 0;
    }

    >div.field {
        margin: 0 0 10px;
        min-height: 62px;

        .label {
            margin: 0 0 2px;
            display: inline-block;
        }

        &.required {
            .label {
                &:after {
                    color: $color-primary;
                    font-size: 13px;
                }
            }
        }


        &:not(.choice) {
            >label.label {
                padding: 0px 5px;
                text-align: left;
                width: auto;
                box-sizing: border-box;
                float: none;
                text-transform: uppercase;
                font-weight: 400;
                font-size: rem(12);
                color: $color-primary;
                display: inline-block;
                font-family: $font-secondary;
                background-color: $color-white;
                position: relative;
                top: 8px;
                margin: 0 10px;
                z-index: 3;
            }

            > .control{
                float: none;
                position: relative;
            }
        }

        &.street {
            .field {
                .label {
                    padding: 0px 5px;
                    text-align: left;
                    width: auto;
                    box-sizing: border-box;
                    float: none;
                    text-transform: uppercase;
                    font-weight: 400;
                    font-size: rem(12);
                    color: $color-primary;
                    height: auto;
                    display: inline-block;
                    font-family: $font-secondary;
                    background-color: $color-white;
                    position: relative;
                    top: 12px;
                    margin: 0 10px;
                    z-index: 5;
                }
            }
        }

        &.choice {
            &:before {
                display: none;
            }
        }

        #firstname, #lastname, #p_name, #p_lastname{
            text-transform: uppercase;

            &::placeholder{
                text-transform: none;
                font-size: 11px;
            }
        }
    }

    .field-half {
        width: 35%;
        float: left;
        min-height: 62px;

        @media (max-width: 768px){
            width: 100%;
            float: none;
        }

        &:not(.choice) {
            > .control {
                float: none;
                width: 92%;

                @media (max-width: 768px){
                    width: 100%;
                }
            }
        }

        &.clear{
            float: none;
        }
    }
}

.column:not(.sidebar-main),
.column:not(.sidebar-additional) {
    form {
        .actions-toolbar {
            margin: 0 auto;
        }
    }
}

form {
    div.actions-toolbar {
        margin: 0 auto;
    }
}

.password-strength-meter {
    font-size: 12px;
}

div.mage-error[generated] {
    color: $color-error;
    font-size: 11px;
}

.mage-error#gender-error{
    position: absolute;
}

.field-error {
    color: $color-error;
    font-size: 11px;
}

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
    border: none;
    border-bottom: 3px solid $color-error !important;
}

.fieldset>.field.required>.label:after,
.fieldset>.fields>.field.required>.label:after,
.fieldset>.field._required>.label:after,
.fieldset>.fields>.field._required>.label:after {
    font-size: 13px;
}


input[type="date"]{
    position: relative;

    &::-webkit-calendar-picker-indicator {
        color: transparent;
        background: none;
        z-index: 1;
    }

    &:before {
        content: '';
        background: url($img-path + "icons/icon-calendar.svg") no-repeat 0 0;
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 9px;
        right: 15px;
        background-size: contain;
      }
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
    width: 47%;
    padding: 0 0px;
}

.ui-datepicker .ui-datepicker-prev span{
    border-color: transparent $color-primary transparent transparent;
}

.ui-datepicker .ui-datepicker-next span{
    border-color: transparent transparent transparent $color-primary;
}

.ui-datepicker .ui-datepicker-title {
    margin: 0 1em;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
    width: 54%;
    margin-right: 3%;
    font-size: 14px;
    padding: 0 5px;
    height: 35px;
}

.ui-datepicker .ui-datepicker-title .ui-datepicker-year{
    font-size: 14px;
}

.toggle-password{
    position: absolute;
    top: 11px;
    right: 15px;
    width: 16px;
    height: 16px;
    z-index: 2;
    cursor: pointer;

    &.eye-open{
        background: url($img-path + "icons/eye-solid.svg") no-repeat 0 0;
        background-size: contain;
    }

    &.eye-slash{
        background: url($img-path + "icons/eye-slash-solid.svg") no-repeat 0 0;
        background-size: contain;
    }
}

.customer-account-create div.mage-error[generated] {
    margin-top: 1px;
}
