.customer-account-login{
    background: $color-gray-2;

    .page-title-wrapper{
        display: none;
    }

    .login-container{
        @include flexbox;
        @include justify-content(space-between);

        @media (max-width: 991px) {
            @include flex-wrap(wrap);
            @include flex-direction(column);
            @include align-items(center);
        }

        &:before{
            display: none;
        }

        .block{
            background: $color-white;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 3rem 4rem;
            width: 37%;
            min-height: 345px;
            float: none;
            margin-bottom: 30px;
            margin-right: 30px;

            @media (max-width: 991px) {
                padding: 20px;
                border-left: none;
                width: 100%;
                min-height: auto;
                box-sizing: border-box;
                margin-right: 0;
            }

            .block-title{
                font-weight: 500;
                font-size: rem(30);
                line-height: 40px;
                color: $color-primary;
                border: none;
                position: relative;
                margin-bottom: 2.5rem;
                font-family: $font-secondary;

                @media (max-width: 991px) {
                    font-size: 25px;
                    line-height: 35px;
                    margin-bottom: 1rem;
                }

                &:after {
                    content: "";
                    width: 30px;
                    height: 5px;
                    background: $color-primary;
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    border-radius: 30px;
                }

                strong{
                    font-weight: 500;
                }
            }

            &.block-new-customer{
                position: relative;
                margin-bottom: 30px;
                margin-right: 0;

                p{
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 25px;
                }

                .block-content{
                    padding-right: 4rem;

                    @media (max-width: 991px) {
                        padding-right: 0;
                    }

                    .actions-toolbar{
                        margin-top: 5rem;

                        @media (max-width: 991px) {
                            margin: 0 auto!important;
                        }
                    }
                }
            }
        }

        .fieldset{
            margin: 0;

            &:after{
                display: none;
            }

            &.login{
                .note{
                    display: none;
                }
            }

            >.field{
                >.control {
                    width: 100%;
                    float: none;

                    @media (max-width: 991px) {
                        width: 100%;
                    }
                }
            }
        }

        .actions-toolbar{
            > .primary{
                float: none;
                margin: 2rem 0 0 0;
            }

            .secondary{
                float: none;
                display: block;
                text-align: left;

                .action{
                    &.remind{
                        text-transform: uppercase;
                        font-size: rem(14);
                        font-family: $font-secondary;
                        margin-top: 0;
                        color: $color-primary;

                        span{
                            color: $color-primary;
                        }

                        &:hover{
                            color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}
