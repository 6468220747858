.page-layout-home {
    .columns .column.main {
        padding-bottom: 0;
    }

    .breadcrumbs{
        display: none;
    }

    .home-block-search{
        background: $color-2;
        padding: 2rem 0 7rem;
        position: relative;

        @media (max-width: 768px) {
            padding: 2rem 0 1rem;
        }

        .container{
            @include flexbox;
            @include align-items(center);
            @include justify-content(space-between);

            @media (max-width: 768px) {
                @include align-items(flex-start);
                @include flex-direction(column);
            }
        }

        h2{
            color: $color-primary;
            font-size: rem(40);
            line-height: 48px;
            font-weight: 500;
            max-width: 500px;
            margin-bottom: 0;

            @media (max-width: 768px) {
                max-width: 130px;
                line-height: 27px;
                font-size: rem(24);
                margin-bottom: 2rem;
            }
        }

        .col-left{
            width: 49%;

            @media (max-width: 768px) {
                width: 100%;
            }
        }

        .col-right{
            width: 40%;

            @media (max-width: 768px) {
                width: 35%;
                position: absolute;
                right: 20px;

                img{
                    max-height: 145px;
                }
            }
        }

        .terms{
            margin-top: 15px;
            max-width: 540px;

            @media (max-width: 768px) {
                text-align: left;
                max-width: 100%;
            }

            span{
                text-transform: uppercase;
                display: inline-block;
                font-size: rem(13);
                font-family: $font-secondary;
                margin-right: 5px;
            }

            ul{
                display: inline;
                margin: 0;
                padding: 0;

                li{
                    list-style: none;
                    display: inline-block;

                    a{
                        background: $color-8;
                        font-size: 13px;
                        line-height: 15px;
                        letter-spacing: 0.03em;
                        border-radius: 5px;
                        padding: 5px 10px;
                        color: $color-secondary;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .home-block-categories{
        margin-top: -4rem;
        position: relative;

        @media (max-width: 768px) {
            margin-top: 2rem;
        }

        .container{
            .wrap{
                @include flexbox;
                @include justify-content(center);
                flex-wrap: wrap;

                @media (max-width: 768px) {
                    @include flex-direction(column);
                    @include align-items(center);
                }

                .box-category{
                    background: $color-white;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
                    border-radius: 10px;
                    width: 26.5%;
                    padding: 30px 25px;
                    margin: 0 0.8rem 2rem;
                    @include flexbox;
                    @include flex-direction(column);
                    @include justify-content(space-between);

                    @media (max-width: 768px) {
                        width: 100%;
                        padding: 15px 12px;
                        margin: 0 auto 2rem;
                        width: 94%;
                        flex-wrap: wrap;
                    }

                    .box-category-title{
                        margin-bottom: 1rem;

                        h3{
                            font-weight: 500;
                            font-size: rem(24);
                            line-height: 30px;
                            color: $color-4;
                            margin-bottom: 0;
                            position: relative;

                            @media (max-width: 768px) {
                                font-size: rem(20);
                                line-height: 25px;
                            }

                                &:after{
                                    content: "";
                                    width: 23px;
                                    height: 3px;
                                    background: $color-4;
                                    border-radius: 10px;
                                    display: block;
                                    position: absolute;
                                    bottom: 5px;
                                    left: 62px;
                                }


                            span{
                                width: 46px;
                                height: 46px;
                                display: inline-block;
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: contain;
                                margin-right: 15px;
                                vertical-align: middle;
                            }

                            .icon-exames{
                                background-image: url($img-path + "icons/icon-exames.svg");
                            }

                            .icon-consultas{
                                background-image: url($img-path + "icons/icon-consultas.svg");
                            }

                            .icon-check-ups{
                                background-image: url($img-path + "icons/icon-check-ups.svg");
                            }

                            .icon-vacinas{
                                background-image: url($img-path + "icons/icon-vacinas.svg");
                            }
                        }
                    }

                    ul{
                        margin: 2rem 0;
                        padding: 0;
                        min-height: 185px;

                        li{
                            list-style: none;
                            position: relative;
                            padding-left: 25px;

                            &:before{
                                content: "";
                                    width: 13px;
                                    height: 13px;
                                    background: url($img-path + "icons/icon-check-red.svg") no-repeat 0 0;
                                    background-size: contain;
                                    position: absolute;
                                    top: 6px;
                                    left: 0;
                            }
                        }
                    }

                    .block-products-list-custom{
                        margin: 0;

                        .box-category-list{
                            margin-bottom: 1.5rem;
                            padding: 0;

                            .box-category-item{
                                @include flexbox;
                                @include justify-content(space-between);
                                @include align-items(flex-end);
                                border-bottom: 1px solid #EBEBEB;
                                padding: 8px 0;
                                min-height: 37px;
                                margin: 0;

                                .product-item-name{
                                    margin: 0;
                                    width: 67%;

                                    a{
                                        font-size: rem(14);
                                        line-height: 18px;
                                        color: $color-secondary;
                                        display: inline-block;

                                        @media (max-width: 768px) {
                                            font-size: rem(14);
                                        }

                                        &:hover{
                                            color: $color-primary;
                                            text-decoration: none;
                                        }
                                    }

                                    span{
                                        color: $color-primary;
                                        text-transform: uppercase;
                                        font-size: rem(9);
                                        font-family: $font-secondary;
                                        letter-spacing: 0.03em;
                                        display: block;
                                    }
                                }

                                .price-box {
                                    width: 32%;
                                    text-align: right;

                                    .price-types{
                                        display: none;
                                    }

                                    .price-label{
                                        color: $color-primary;
                                        text-transform: uppercase;
                                        font-size: rem(9);
                                        font-family: $font-secondary;
                                        text-align: right;
                                        letter-spacing: 0.03em;
                                        display: inline-block;
                                    }

                                    .price-container{
                                        span{
                                            display: inline-block;
                                        }

                                        .price{
                                            color: $color-primary;
                                            text-transform: uppercase;
                                            font-size: rem(14);
                                            font-family: $font-secondary;
                                            text-align: right;
                                            display: inline-block;

                                            small{
                                                font-size: rem(10);
                                                letter-spacing: 0.03em;
                                            }
                                        }
                                    }

                                    .old-price{
                                        clear: both;
                                        display: block;
                                        text-decoration: none;
                                        line-height: 14px;

                                        .price-label{
                                            color: $color-secondary;
                                        }

                                        .price{
                                            color: $color-secondary;
                                            font-size: rem(12);
                                            text-decoration: line-through;
                                        }
                                    }

                                    .special-price{
                                        clear: both;
                                        display: block;
                                        line-height: 14px;
                                    }
                                }
                            }
                        }
                    }

                    .btn{
                        background: $color-primary;
                        color: $color-white;
                        font-size: rem(18);
                        font-family: $font-secondary;
                        height: 45px;
                        text-align: center;
                        border-radius: 7px;
                        padding: 0 20px;
                        display: inline-block;
                        line-height: 45px;
                        margin: 0 auto;

                        &:hover{
                            text-decoration: none;
                            background: lighten($color-primary, 10%);
                        }

                        @media (max-width: 768px) {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .home-block-health-insurance{
        padding-bottom: 4rem;

        @media (max-width: 768px) {
            padding-bottom: 1rem;
        }

        .wrap{
            background: $color-white;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 30px;
            text-align: center;

            @media (max-width: 768px) {
                padding: 10px 0;
                box-shadow: none;
                background: none;
            }

            h2{
                font-weight: 500;
                font-size: rem(24);
                line-height: 30px;
                color: $color-4;
                margin: 0 auto 2rem;
                text-align: center;

                &:after{
                    content: "";
                    width: 23px;
                    height: 3px;
                    background: $color-4;
                    border-radius: 10px;
                    display: block;
                    margin: 5px auto 3px;
                }
            }

            p{
                text-align: center;
                display: inline-block;

                @media (max-width: 768px) {
                    display: block;
                }
            }

            .list-convenios{
                @include flexbox;
                @include justify-content(space-between);
                flex-wrap: wrap;

                .item{
                    width: 19%;
                    border-bottom: 1px solid #EBEBEB;
                    padding: 5px 0;
                    text-align: center;
                    margin: 0px 15px;

                    @media (max-width: 768px) {
                        width: 47%;
                        margin: 0px 1%;
                    }

                    img{
                        max-width: 100px;
                    }
                }
            }
        }
    }

    .home-block-text-1{
        background: $color-white;
        padding: 4rem 0;
        position: relative;

        @media (max-width: 768px) {
            padding: 3rem 0 1rem;
        }

        .container{
            @include flexbox;
            @include justify-content(space-around);
        }

        h2{
            font-weight: 500;
            font-size: rem(30);
            line-height: 30px;
            color: $color-secondary;
            margin: 2rem 0 2rem;

            @media (max-width: 768px) {
                max-width: 57%;
                margin: 0 0 3rem;
                font-size: rem(25);
            }

            &:after{
                content: "";
                width: 23px;
                height: 3px;
                background: $color-secondary;
                border-radius: 10px;
                display: block;
                margin: 13px 0 0;
            }
        }

        p{
            font-size: rem(16);

            @media (max-width: 768px) {
                font-size: rem(14);
            }
        }

        .block-text{
            max-width: 445px;

            @media (max-width: 768px) {
                max-width: 100%;
            }
        }

        img{
            @media (max-width: 768px) {
               max-width: 107px;
               position: absolute;
               right: 15px;
               top: 30px;
            }
        }
    }

    .home-block-text-2{
        background: $color-2;
        padding: 4rem 0;
        position: relative;

        @media (max-width: 768px) {
            padding: 3rem 0 1rem;
        }

        .container{
            @include flexbox;
            @include justify-content(space-around);
        }

        h2{
            font-weight: 500;
            font-size: rem(30);
            line-height: 30px;
            color: $color-secondary;
            margin: 2rem 0 2rem;

            @media (max-width: 768px) {
                max-width: 57%;
                margin: 0 0 3rem;
                font-size: rem(25);
            }

            &:after{
                content: "";
                width: 23px;
                height: 3px;
                background: $color-secondary;
                border-radius: 10px;
                display: block;
                margin: 13px 0 0;
            }
        }

        p{
            font-size: rem(16);

            @media (max-width: 768px) {
                font-size: rem(14);
            }
        }

        .block-text{
            max-width: 445px;

            @media (max-width: 768px) {
                max-width: 100%;
            }
        }

        img{
            @media (max-width: 768px) {
               max-width: 107px;
               position: absolute;
               right: 15px;
               top: 30px;
            }
        }
    }

    .block-locations{
        background: $color-white;
    }

    .home-block-text-seo{
        background: $color-white;
        padding: 2rem 0 4rem;

        @media (max-width: 768px) {
            padding: 2rem 0 1rem;
        }

        .container{
            max-width: 740px;
        }

        h2{
            font-weight: 500;
            font-size: rem(30);
            line-height: 30px;
            color: $color-primary;
            margin: 0 auto 1.5rem;
            text-align: center;

            @media (max-width: 768px) {
                font-size: rem(22);
            }
        }

        h3{
            font-weight: 500;
            font-size: rem(16);
            line-height: 20px;
            color: $color-4;
            margin: 0 auto 2.5rem;
            text-align: center;

            strong{
                color: $color-primary;
                font-weight: 500;
            }
        }
    }
}
