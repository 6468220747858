.page{
    .messages{
        .message{
            font-size: rem(14);
        }
    }
}


.message{
    &.info{
        font-size: rem(14);
        clear: both;
    }
}
