/**
 * Variables
 */

@import "base/variables";

/**
 * Base
 */

@import "base/base";
@import "base/mixins";
@import "base/typography";
@import "base/fonts";
@import "base/functions";
@import "base/svg-icons";

/**
 * Vendors
 */
@import "vendors/slick";

/**
 * Layout
 */
@import "layout/header";
@import "layout/footer";
@import "layout/menu";
@import "layout/grid";
@import "layout/pages";
@import "layout/sidebar";

/**
 * Components
 */
@import "components/buttons";
@import "components/forms";
@import "components/swatches";
@import "components/product-grid";
@import "components/banner-slider";
@import "components/breadcrumbs";
@import "components/toolbar";
@import "components/titles";
@import "components/alerts";
@import "components/modal";
@import "components/cookies";
@import "components/search";
@import "components/block-image-text";
@import "components/locations";
@import "components/accordion";
/**
 * Pages
 */
@import "pages/home";
@import "pages/home-initial";
@import "pages/product";
@import "pages/login";
@import "pages/forgot-password";
@import "pages/cart";
@import "pages/faq";
@import "pages/contact";
@import "pages/my-account";
@import "pages/register";
@import "pages/checkout";
@import "pages/404";
@import "pages/health-insurance";
@import "pages/checkout-custom";
@import "pages/order-print";
