.page-print .logo {
    float: none;
    margin: 20px 0;
    display: block;
}

.page-print.account .column.main .block .block-content .box{
    padding: 0 !important;
    background: none !important;
}

.page-print .page-main .page-title-wrapper .page-title {
    display: block !important;
}

.page-print.account .column.main {
    width: 100% !important;
}

.page-print.account .table-order-items .amount{
    text-align: right !important;
}

.price-including-tax, .price-excluding-tax {
    font-size: 16px;
}

.account .column.main .block:not(.widget) .box-title > span{
    font-size: 16px !important;
}

.account .column.main .block:not(.widget) .block-title > strong{
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.order-details-items .order-title > strong{
    display: none;
}


.footer-copyright {
    padding: 20px 0;

    .container{
        padding: 0;
    }

    .copyright{
        padding-bottom: 10px;
        display: block;
    }
}
