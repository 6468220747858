@media (min-width: 768px){
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .footer.content,
    .page-wrapper>.widget,
    .page-wrapper>.page-bottom,
    .block.category.event,
    .top-container,
    .page-main {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1150px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .container {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1150px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}
