.accordion{
    &-title{
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
        background-color: $color-white;
        border: none;
        padding: 15px;
        font-weight: 400;
        font-size: rem(18);
        line-height: 15px;
        color: $color-primary;
        font-family: $font-secondary;
        border-radius: 3px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

        &:after{
            content: "";
            background-image: url($img-path + "icons/arrow-bottom-red.svg");
            background-repeat: no-repeat;
            background-position: 0 center;
            background-size: 14px 17px;
            width: 14px;
            height: 17px;
            position: absolute;
            right: 20px;
            top: 17px;
        }

        &.active{
            &:after{
                background-image: url($img-path + "icons/arrow-top-red.svg");
            }
        }

        h3{
            margin: 0!important;
            font-size: rem(18)!important;
            font-weight: 400!important;
            font-family: $font-secondary!important;
            z-index: 2;
            position: relative;
        }
    }

    &-content{
        background: $color-white;
        padding: 5px 20px 15px;
        display: none;
        border: none;
        margin-top: -10px;
        border-radius: 0 0 3px 3px;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
        position: relative;
        margin-bottom: 10px;
    }
}
