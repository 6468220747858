.products,
.block-products-list {
    .product-items {
        .product-item {
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
            border-radius: 10px;
            background: $color-white;
            position: relative;

            margin-bottom: 30px;

            @media (max-width: 480px) {
                width: 100%!important;
                max-width: 255px;
                margin: 0 auto 20px;
                display: block;
            }

            .product-item-description {
                p{
                    font-size: rem(14);
                }

                .more{
                    display: none;
                }
            }

            .product-item-info {
                width: 100%;

                .product-item-photo {
                    .product-image-container {
                        display: block;
                        max-width: 100%;
                        margin: 0 auto 20px;
                        text-align: center;
                        padding-top: 30px;
                    }
                }

                .product-item-details {
                    padding: 20px;

                    .product-item-name {
                        font-size: rem(18);
                        line-height: 20px;
                        min-height: 73px;
                        font-family: $font-secondary;
                        margin: 0 0 15px;

                        .product-item-link {
                            color: $color-primary;

                            &:after{
                                content: "";
                                background: $color-primary;
                                width: 23px;
                                height: 3px;
                                display: block;
                                margin-top: 10px;
                            }
                        }
                    }

                    .product-item-description {
                        margin: 5px 0;
                        font-size: rem(16);
                        color: $color-secondary;
                        max-height: 70px;
                        overflow: hidden;
                    }

                    .price-box {
                        margin: 15px 0 0;
                        font-size: rem(16);
                        border-top: 1px solid #EBEBEB;
                        padding: 10px 0;

                        .price-wrapper {
                            .price {
                                color: $color-primary;
                                font-size: rem(18);
                                font-family: $font-secondary;
                                font-weight: 500;
                            }
                        }

                        .normal-price {
                            .price-label {
                                color: $color-primary;
                                font-size: rem(16);
                                font-weight: 400;
                            }

                            .price-wrapper {
                                .price {
                                    color: $color-primary;
                                    font-size: rem(16);
                                }
                            }
                        }

                        .special-price {
                            .price-label {
                                color: $color-primary;
                                font-size: rem(14);
                                font-weight: 400;
                                text-transform: uppercase;
                                font-family: $font-secondary;

                                &::after{
                                    display: none;
                                }
                            }

                            .price-wrapper {
                                .price {
                                    color: $color-primary;
                                    font-size: rem(18);
                                }
                            }
                        }

                        .old-price {
                            text-decoration: none;

                            .price-label {
                                color: $color-7;
                                font-size: rem(14);
                                text-transform: uppercase;
                                font-weight: 400;
                                font-family: $font-secondary;

                                &::after{
                                    display: none;
                                }
                            }

                            .price-wrapper {
                                .price {
                                    color: $color-7;
                                    font-size: rem(14);
                                    font-weight: 400;
                                    text-decoration: line-through;
                                }
                            }
                        }
                    }

                    .price-types{
                        @include flex-direction(column);
                        @include align-items(flex-start);

                        .price-site{
                            margin-right: 0;
                        }

                        .price-discount{
                            margin-bottom: 10px;
                            margin-right: 0;
                        }
                    }

                    .price-info-parcel{
                        display: none;
                    }

                    .product-item-actions {
                        .actions-primary {
                            .btn-more{
                                background-color: $color-white;
                                color: $color-4;
                                font-weight: 500;
                                font-size: rem(12);
                                line-height: 30px;
                                height: 35px;
                                border: 2px solid $color-4;
                                border-radius: 7px;
                                text-align: center;
                                padding: 0 20px;
                                box-sizing: border-box;

                                &:hover{
                                    background-color: $color-4;
                                    color: $color-white;
                                    text-decoration: none;
                                }
                            }

                            .tocart {
                                background: $color-primary;
                                color: $color-white;
                                border-radius: 7px;
                                font-weight: 500;
                                font-size: rem(12);
                                line-height: 30px;
                                height: 35px;
                                padding: 0 11px;
                                border: 2px solid $color-primary;

                                &:hover {
                                    text-decoration: none;
                                    background: lighten($color-primary, 12);
                                    border-color: lighten($color-primary, 12);
                                }

                                span{
                                    &:before{
                                        content: "";
                                        background: url($img-path + "icons/icon-cart-white.svg") no-repeat 0 0;
                                        background-size: contain;
                                        width: 17px;
                                        height: 17px;
                                        display: inline-block;
                                        margin-right: 7px;
                                        vertical-align: middle;
                                        position: relative;
                                        top: -1px;
                                    }
                                }
                            }

                            .field {
                                &.qty {
                                    display: block;
                                    margin: 0;

                                    .label {
                                        font-weight: 400;
                                        font-size: rem(16);
                                        line-height: 25px;
                                        color: $color-secondary;
                                        text-transform: none;
                                        display: inline-block;
                                        padding: 0 7px 0 0;
                                        margin: 0;
                                    }

                                    .control{
                                        display: inline-block;

                                        .input-group{
                                            border: 2px solid $color-secondary;
                                            border-radius: 7px;
                                            height: 35px;

                                            &__addon{
                                                .input-group__button{
                                                    background: none;
                                                    border: none;
                                                    padding: 0;
                                                    color: $color-secondary;
                                                    font-size: rem(15);
                                                    font-weight: 500;
                                                    font-family: $font-secondary;
                                                    height: 35px;
                                                    width: 25px;
                                                }
                                            }

                                            &__input {
                                                height: 35px;
                                                width: 46px;
                                                border: none;
                                                color: $color-secondary;
                                                font-size: rem(14);
                                                font-weight: 500;
                                                text-align: center;
                                                font-family: $font-secondary;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .actions-secondary {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            z-index: 5;

                            .towishlist {
                                &:before {
                                    font-size: 40px;
                                    color: $color-primary;
                                }

                                &:hover {
                                    &:before {
                                        content: '\e600';
                                    }
                                }
                            }

                            .tocompare {
                                display: none;
                            }
                        }
                    }

                    .product-reviews-summary {
                        display: none;
                    }

                    .control{
                        label{
                            font-weight: 400;
                            font-size: rem(16);
                            line-height: 25px;
                            color: $color-secondary;
                            text-transform: none;
                            display: inline-block;
                            padding: 0 7px 0 0;
                            margin: 0;
                        }

                        .input-text{
                            height: 35px;
                            width: 60px;
                            border: none;
                            color: $color-secondary;
                            font-size: rem(14);
                            font-weight: 500;
                            text-align: center;
                            font-family: $font-secondary;
                            border: 2px solid #7D7D7D;
                            border-radius: 7px;
                        }
                    }
                }
            }
        }
    }

    &.grid {
        .product-items {
            .product-item {
                min-height: 460px;

                .product-item-details {
                     padding: 20px 20px 110px;
                }

                .product-item-inner{


                    .product-item-actions {
                        margin: 0;

                        .actions-primary {
                            .actions-buttons {
                                border-top: 1px solid #EBEBEB;
                                position: absolute;
                                bottom: 20px;
                                padding: 15px 0 0;
                                width: 85%;
                                @include flexbox;
                                @include justify-content(space-between);
                            }
                        }
                    }
                }
            }
        }

        &.products-related{
            .field.choice.related{
                display: none;
            }
        }
    }
}

@media (min-width: 1024px) {
    .page-products {
        .products-grid {
            .product-item {
                margin-left: 2%;
                padding: 0;
                width: calc((100% - 6%) / 3);

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }

                &:nth-child(4n + 1) {
                    margin-left: 2%;
                }

                &:nth-child(5n + 1) {
                    margin-left: 2%;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .page-layout-1column {
        .block.widget, .block.related{
            .products-grid {
                .product-items {
                    .product-item {
                        margin-left: 2%;
                        padding: 0;
                        width: calc((100% - 6%) / 4);

                        &:nth-child(3n + 1) {
                            margin-left: 2%;
                        }

                        &:nth-child(4n + 1) {
                            margin-left: 2%;
                        }

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.foreign-sales{
    clear: both;
    position: relative;

    .box{
        background: #F9F9F9;
        border-radius: 7px;
        padding: 5px 10px;

        span{
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.03em;
            color: #7D7D7D;
            font-family: $font-secondary;
            display: inline-block;
            vertical-align: middle;
        }

        .icon-info{
            background: url($img-path + "icons/icon-info.svg") no-repeat 0 0;
            background-size: contain;
            width: 15px;
            height: 15px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    .tooltip{
        background: #FFFBEB;
        border: 1px solid #D6D6D6;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        border-radius: 7px;
        padding: 10px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #7D7D7D;
        font-size: 13px;
        line-height: 15px;
        font-family: $font-secondary;
        width: 270px;
        position: absolute;
        display: none;
        z-index: 2;
        margin: 0 auto;
        top: 40px;
        left: -40px;
    }

    &:hover{
        .tooltip{
             display: block;
        }
    }
}

.catalog-product-view{
    .foreign-sales{
        margin-right: 20px;

        .box{
            background: $color-white;
        }
    }
}

