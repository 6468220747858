.mp-banner-slider{
    .owl-carousel{
        @media (max-width: 460px){
            .owl-item {
                img{
                    object-fit: cover;
                    object-position: center;
                    height: 165px;
                }
            }
        }

        .owl-nav{
            button{
                span{
                    position: absolute;
                    top: 40%;
                    font-size: 37px;
                    color: #fff;
                    width: 30px;
                    height: 30px;
                    background: rgba(0, 0, 0, 0.25);
                    line-height: 25px;

                    @media (max-width: 480px){
                        top: 30%;
                    }
                }
            }
        }
    }
}