.nav-sections {
    -webkit-overflow-scrolling: touch;
    -webkit-transition: left 0.3s;
    -moz-transition: left 0.3s;
    -ms-transition: left 0.3s;
    transition: left 0.3s;
    height: 100%;
    left: -350px;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 346px;
    background: $color-4;
    z-index: 99;

    .header-sections{
        @media (max-width: 768px) {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
        }

        .close-menu{
            position: relative;
            text-align: right;
            padding: 30px 30px 0px;
            background: $color-9;

            @media (max-width: 768px) {
                padding: 20px 15px 0px;
                float: right;
            }
        }

        .sections-logo{
            padding: 30px;
            background: $color-9;

            @media (max-width: 768px) {
                padding: 20px;
            }

            img{
                max-width: 180px;
                width: 100%;

                @media (max-width: 768px) {
                    max-width: 120px;
                }
            }
        }
    }

    .navigation {
        background: $color-4;
        padding: 25px 30px;
        margin: 0;


        @media (max-width: 768px) {
            padding: 15px 20px;
        }

        ul{
            padding: 0;
        }

        li{
            display: block;
            margin: 0;
            padding: 5px 0;

            &.all-category{
                display: none;
            }

            &.level0 {
                color: $color-white;

                @media (max-width: 768px) {
                    border: none;
                }

                > .level-top {
                    color: $color-white;
                    font-size: rem(13);
                    font-weight: 400;
                    text-transform: uppercase;
                    font-family: $font-secondary;
                    padding: 0;
                    line-height: 23px;
                    display: block;

                    &:hover {
                        color: #ccc;
                    }
                }

                &.has-active {
                    >.level-top {
                        color:#ccc;
                        border: none;

                        &:hover {
                            color: #ccc;
                        }
                    }
                }


                    &.parent {
                        >.level-top {
                            >.ui-menu-icon {
                                right: 0;
                                text-decoration: none;

                                &:after {
                                    font-size: 26px;
                                }
                            }

                            @media (max-width: 768px) {
                                &:after{
                                    top: 1px;
                                }
                            }
                        }
                    }

                    &.active {
                        >.level-top {
                            color: #ccc;
                            border: none;
                        }
                    }

                    .submenu {
                        box-shadow: none;
                        border: none;
                        background: none;
                        position: relative;
                        margin: 0!important;
                        top: 0!important;
                        padding: 5px 0 0;

                        li {
                            @media (max-width: 767px) {
                                padding: 0 15px;
                            }

                            a {
                                color: $color-white;
                                text-transform: uppercase;
                                font-size: rem(13);
                                font-weight: 400;
                                font-family: $font-primary;
                                padding: 3px 20px;

                                @media (max-width: 767px) {
                                    color: $color-white;
                                    padding: 6px 0;
                                }

                                &:hover, &:active {
                                    background: none;
                                    color:#ccc;
                                }

                                &.ui-state-focus{
                                    background: none;
                                }
                            }

                            &:hover {
                                background: none;
                            }

                            &.active{
                                a{
                                    border: none;
                                    color:#ccc;
                                }
                            }
                        }
                    }

            }
        }
    }

    .section-bottom{
        position: absolute;
        bottom: 0;
        border-top: 1px solid #727272;
        padding: 10px 0 0 0;
        margin: 0 30px 0;
        width: 82%;

        @media (max-width: 768px) {
            margin: 0 20px 0;
            width: 88%;
            position: relative;
        }

        ul{
            padding: 0;
            margin-bottom: 1.5rem;

            li{
                display: block;
                margin: 0;
                padding: 2px 0;

                a{
                    color: $color-white;
                    font-size: rem(11);
                    font-weight: 400;
                    text-transform: uppercase;
                    font-family: $font-secondary;
                    padding: 0;
                    line-height: 20px;
                    display: block;

                    &:hover {
                        color: #ccc;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.nav-toggle {
    display: block;
    position: relative;
    left: 0;
    top: 0;
    padding-top: 14px;
}

.nav-open {
    .page-wrapper{
        left: 0;
    }

    .nav-sections {
        left: 0;
        z-index: 1000;

        .nav-sections-item-content {
            box-sizing: border-box;
            float: none;
            margin-left: 0;
            margin-top: 0;
            width: 100%;
            padding: 0;
        }

        .header.links {
            border: none;

            @media (max-width: 768px) {
                padding-top: 5rem;
            }


            li {
                font-size: rem(13);
                color: $color-white;
                text-transform: uppercase;
                font-weight: 400;


                &.greet.welcome {
                    border: none;

                    @media (max-width: 768px) {
                        padding: 0.5rem 20px;
                    }
                }

                &.authorization-link{
                    a{
                        span{
                            font-weight: 700;
                        }
                    }
                }

                a {
                    color: $color-white;
                    text-transform: uppercase;
                    font-weight: 400;
                    border-top: none;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

                    @media (max-width: 768px) {
                        border: none;
                    }
                }
            }
        }

    }
}

