/* Font import example*/
// usando o mixin @include font-face('Nome da Fonte', pasta/arquivo-da-fonte, peso, estilo, formatos);
/* @include font-face('FontName', FontFolder/fontfile, 400, normal, eot woff svg); */


//@include new-font-face('Roboto', roboto/Roboto-Medium, 500, normal, eot woff woff2);
@include font-face('Open Sans', '../fonts/opensans/OpenSans-Regular', 400, normal, eot woff woff2);
@include font-face('Open Sans', '../fonts/opensans/OpenSans-Medium', 500, normal, eot woff woff2);
@include font-face('Open Sans', '../fonts/opensans/OpenSans-SemiBold', 600, normal, eot woff woff2);
@include font-face('Open Sans', '../fonts/opensans/OpenSans-Bold', 700, normal, eot woff woff2);
@include font-face('Open Sans', '../fonts/opensans/OpenSans-ExtraBold', 800, normal, eot woff woff2);
@include font-face('Ubuntu', '../fonts/ubuntu/Ubuntu-Regular', 400, normal, eot woff woff2);
@include font-face('Ubuntu', '../fonts/ubuntu/Ubuntu-Medium', 500, normal, eot woff woff2);
@include font-face('Ubuntu', '../fonts/ubuntu/Ubuntu-Bold', 700, normal, eot woff woff2);
