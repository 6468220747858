.toolbar{
    padding: 0;
    clear: both;

    .toolbar-amount{
        display: none;
    }

    .modes {
        float: right;
        margin: 10px 0 0 20px;

        .modes-mode {
            border: none;
            padding: 0 15px 0 0;

            span{
                display: inline-block;
                position: relative;
                height: auto;
                width: auto;
                vertical-align: middle;
                font-size: 13px;
                text-transform: uppercase;
                color: $color-secondary;
            }

            &.mode-list{
                &:before {
                    background-image: get-icon('list', $color-secondary);
                }

                &.active {
                    &:before {
                        background-image: get-icon('list', $color-primary);
                    }

                    span{
                        color: $color-primary;
                    }
                }
            }

            &:before {
                content: "";
                background-image: get-icon('grid', $color-secondary);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }

            &.active {
                &:before {
                    background-image: get-icon('grid', $color-primary);
                }

                span{
                    color: $color-primary;
                }
            }

            &:hover {
                background: none;
                text-decoration: none;
            }
        }
    }

    .toolbar-sorter{
        float: left;

        .sorter-label{
            font-size: rem(14);
            text-transform: uppercase;
            color: $color-secondary;
            font-family: $font-secondary;
        }

        .sorter-options{
            font-size: rem(14);
            color: $color-secondary;
            border: 1px solid #B4B4B4;
            border-radius: 5px;
            font-family: $font-secondary;
            padding: 0 5px;
            height: 35px;
        }

        .sorter-action{
            display: none;
        }
    }

    .pages{
        float: none!important;

        .items{
            .item{
                font-size: rem(16);
                line-height: 27px;
                letter-spacing: normal;
                margin: 0 15px 0 0;
                display: inline-block;

                &.current{
                    .page{
                        color: $color-white;
                        background: $color-primary;
                        border-radius: 100%;
                        width: 40px;
                        padding: 0;
                        height: 40px;
                        line-height: 40px;
                        font-weight: 400;
                    }
                }

                a{
                    color: $color-1;
                    background: none;
                    border-radius: 100%;
                    padding: 0;
                    font-weight: 400;

                    &.next{
                        background: none;
                        border: none;
                        margin-left: 5px;

                        &:before{
                            color: $color-primary;
                        }
                    }

                    &.previous {
                        background: none;
                        border: none;
                        margin-left: 5px;

                        &:before {
                            color: $color-primary;
                        }
                    }
                }
            }
        }
    }

    .limiter {
        display: block;
        float: right;
        margin-left: 1.3rem;

        @media (max-width: 768px) {
            display: none;
        }

        .limiter-label{
            font-size: rem(13);
            text-transform: uppercase;
        }

        label {
            span {
                font-size: rem(13);
                text-transform: uppercase;
                color: $color-secondary;
            }
        }

        .limiter-text {
            display: none;
        }

        .limiter-options {
            color: $color-secondary;
            border: 1px solid #b4b4b4;
            font-size: rem(13);
        }
    }

    &.wishlist-toolbar{
        .toolbar-amount{
            display: none;
        }

    }
}


.products.wrapper{
    ~.toolbar {
        .limiter {
            display: none;
        }
    }
}

.page-products {
    .toolbar {
        .limiter{
            display: block;
        }

    }
}
