/*
 * Variables
 */

/*** COLORs ***/
$color-primary: #CE151A;
$color-secondary: #7D7D7D;
$color-white: #FFFFFF;
$color-black: #000000;
$color-gray: #f5f5f5;
$color-gray-2: #f9f9f9;
$color-1: #e5e5e5;
$color-2: #f0f0f0;
$color-3: #FFE8E8;
$color-4: #4D4A47;
$color-5: #0079BE;
$color-6: #71DB89;
$color-7: #9D9CA1;
$color-8: #dedede;
$color-9: #5C5C5C;
$color-10: #d1d1d1;
$color-success: #0A9E5A;
$color-error: #FF9B04;

/*** FONTs ***/
$font-primary: "Open Sans", sans-serif;
$font-secondary: "Ubuntu", sans-serif;

$font-size: 16px;

$transition-links: all .2s ease;

$img-path: "../images/";
$font-path: "../fonts/";
