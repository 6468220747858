.mageprince-faq-collection {
    .group-title{
        color: $color-secondary;
    }

    .faq-accordion {
        .ui-accordion-header {
            margin-bottom: 10px;
            position: relative;
            cursor: pointer;
            background-color: $color-white;
            border: none;
            padding: 20px;
            font-weight: 400;
            font-size: rem(18);
            line-height: 20px;
            color: $color-primary;
            font-family: $font-secondary;
            border-radius: 3px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

            @media (max-width: 991px) {
                padding: 15px;
                font-size: rem(14);
            }

            &:hover{
                background-color: $color-white;
                color: $color-primary;
            }

            &:after{
                content: "";
                background-image: url($img-path + "icons/arrow-bottom-red.svg");
                background-repeat: no-repeat;
                background-position: 98% center;
                background-size: 14px 17px;
                width: 14px;
                height: 17px;
            }

            &.ui-accordion-header-active{
                &:after {
                    background-image: url($img-path + "icons/arrow-top-red.svg");
                }
            }
        }

        .allow {
            &.active {
                background-image: url($img-path + "icons/arrow-top-red.svg");
            }
        }

        .ui-accordion-content {
            background: $color-white;
            padding: 10px 20px 15px;
            display: none;
            border: none;
            margin-top: -10px;
            border-radius: 0 0 3px 3px;
            box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
            position: relative;

            p{
                font-size: rem(14);
                margin-bottom: 1;
                color: $color-secondary;
            }
        }
    }
}
