.sidebar{
    &.sidebar-main{
        padding-right: 1.5%;

        @media (max-width: 768px) {
            -ms-flex-order: 0;
            -webkit-order: 0;
            order: 0;
        }

        .block.filter{
            @media (max-width: 768px) {
                margin-bottom: 15px;
            }

            .filter-title{
                margin-bottom: 20px;
                border: 1px solid #b4b4b4;
                padding: 7px 5px;
                text-transform: uppercase;

                strong{
                    font-size: 13px;
                    font-weight: 400;
                    color: $color-primary;
                    display: block;

                    &:after {
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        font-size: 35px;
                        line-height: inherit;
                        content: '\e622';
                        font-family: 'icons-blank-theme';
                        vertical-align: middle;
                        display: inline-block;
                        font-weight: normal;
                        overflow: hidden;
                        speak: none;
                        text-align: center;
                        position: absolute;
                        right: 2px;
                        top: -8px;
                        color: $color-primary;
                    }
                }

                @media (min-width: 768px){
                    display: none;
                }
            }

            .filter-current{
                .item{
                    padding-left: 20px;
                    position: relative;
                    z-index: 1;

                    .filter-label{
                        color: $color-secondary;
                        text-transform: uppercase;
                        font-size: rem(13);
                        font-weight: 700;
                        display: inline-block;
                    }

                    .filter-value{
                        color: $color-secondary;
                        text-transform: uppercase;
                        font-size: rem(13);
                    }
                }

                .action.remove{
                    left: -6px;
                    position: absolute;
                    top: -1px;

                    &:before{
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        font-size: 28px;
                        line-height: 15px;
                        color: #757575;
                        content: '\e616';
                        font-family: 'icons-blank-theme';
                        vertical-align: middle;
                        display: inline-block;
                        font-weight: normal;
                        overflow: hidden;
                        speak: none;
                        text-align: center;
                    }

                    span{
                        font-size: 0;
                    }
                }
            }

            .filter-content{
                .filter-options{
                    .filter-options-item{
                        background: $color-white;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
                        border-radius: 10px;
                        padding: 20px;
                        margin: 0 0 25px;

                         @media (max-width: 767px) {
                            box-shadow: none;
                            margin: 0;
                            padding-bottom: 0;
                         }

                        &.active{
                            .filter-options-title {
                                &:after{
                                    content: '\e621';
                                }
                            }
                        }

                        .filter-options-title{
                            color: $color-primary;
                            font-weight: 400;
                            font-size: rem(18);
                            line-height: 22px;
                            border-bottom: 1px solid #E5E5E5;
                            padding: 0 0 10px;
                            margin: 0 0 15px;
                            position: relative;
                            font-family: $font-secondary;

                            &:after{
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                font-size: 35px;
                                line-height: inherit;
                                color: $color-primary;
                                content: '\e622';
                                font-family: 'icons-blank-theme';
                                vertical-align: middle;
                                display: inline-block;
                                font-weight: normal;
                                overflow: hidden;
                                speak: none;
                                text-align: center;
                                position: absolute;
                                right: -9px;
                                top: 1px;
                            }
                        }

                        .filter-options-content{
                            margin: 0;

                            .items{
                                .item{
                                    a{
                                        color: $color-secondary;
                                        text-transform: uppercase;
                                        font-size: rem(14);
                                    }
                                }
                            }
                        }
                    }
                }

                .filter-actions {
                    .clear {
                        span {
                            font-size: rem(13);
                        }
                    }
                }
            }

            @media (min-width: 768px) {
                &.active,
                .filter-options {
                    .filter-options {
                        background: transparent;
                        clear: both;
                        display: block;
                        overflow: initial;
                        position: static;
                    }
                }
            }

            .filter-subtitle {
               color: $color-primary;
               font-size: rem(20);
               font-weight: 500;
               font-family: $font-secondary;
               margin-bottom: 1rem;

               @media (max-width: 768px) {
                display: none;
               }

               &:before{
                    content: "";
                    background: url($img-path + "icons/icon-filter.svg") no-repeat 0 0;
                    background-size: contain;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin-right: 15px;
                    vertical-align: middle;
               }
            }

            @media (max-width: 768px) {
                .filter-options {
                    display: none;
                    margin: 0;
                }

                &.active {
                    display: block;
                    position: relative;
                    visibility: visible;
                    z-index: 99;

                    .filter-subtitle {
                        background: $color-white;
                        display: block;
                        font-size: rem(16);
                        font-weight: 400;
                        height: 50px;
                        left: 0;
                        line-height: 32px;
                        position: fixed;
                        right: 0;
                        top: 0;
                        z-index: 1;
                        color: $color-primary;
                        padding: 7px 20px 0;
                    }

                    .filter-title{
                        border: none;
                        border-bottom: 1px solid #ccc;
                        height: 48px;
                        margin: 0;
                        left: 0;
                        position: fixed;
                        right: 0;
                        top: 0;
                        z-index: 2;
                        padding: 0;

                        &:after{
                            display: none;
                        }

                        strong{
                            box-shadow: none;
                            background: none;
                            border: 0;
                            color: transparent;
                            left: auto;
                            right: 3px;
                            top: 3px;
                            display: inline-block;
                            text-decoration: none;
                            position: absolute;

                            &:after{
                                -moz-osx-font-smoothing: grayscale;
                                font-size: 30px;
                                line-height: inherit;
                                color: #7d7d7d;
                                content: '\e616';
                                font-family: 'icons-blank-theme';
                                vertical-align: middle;
                                display: inline-block;
                                font-weight: normal;
                                overflow: hidden;
                                speak: none;
                                text-align: center;
                                top: 0;
                            }
                        }
                    }

                    .filter-options {
                        background: #fff;
                        bottom: 0;
                        display: block;
                        left: 0;
                        overflow: scroll;
                        position: fixed;
                        right: 0;
                        top: 50px;
                        z-index: 10;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px){
    body.filter-active .columns {
        z-index: 999;
    }
}
