.customer-account-create {
    .page-title-wrapper{
        text-align: left!important;
        margin: 0 0 3rem!important;

        .page-title {
            position: relative;

            &:after {
                content: "";
                width: 30px;
                height: 5px;
                background: $color-primary;
                position: absolute;
                bottom: -14px;
                left: 0;
                border-radius: 30px;
            }
        }
    }

    .column.main{
        background: $color-white;
        border-radius: 10px;
        padding: 3rem 4rem;
        margin: 1rem auto 6rem;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

        @media (max-width: 991px) {
            padding: 20px;
        }
    }

    .form {
        &.create.account {
            width: 100%;

            .fieldset {
                width: 100%;
                margin-bottom: 20px;

                &.info, &.companie{
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                &.phones{
                    padding-top: 10px;
                    border-radius: 0px 0 3px 3px;
                }

                .legend {
                    color: $color-primary;
                    font-size: rem(20);
                    text-transform: uppercase;
                    font-weight: 500;
                    font-family: $font-secondary;
                    margin: 1rem 0 1rem;

                    @media (max-width: 991px) {
                        font-size: rem(18);
                    }

                    &.field-pj {
                        display: none;
                    }
                }

                &.type-person{
                    .legend{
                        font-size: rem(14);
                        margin: 0 0 15px;
                    }
                }

                .field-group {
                    clear: both;

                    &.field-half {
                        clear: none;
                        width: 35%;
                        float: left;
                        min-height: 58px;

                        @media (max-width: 768px) {}
                    }

                    &.field-pj {
                        display: none;

                        .choice-inline {
                            margin-top: 20px;
                        }

                    }
                }

                .field {
                    min-height: 62px;
                    margin: 0 0 10px;

                    &.choice {
                        min-height: auto;
                        margin: 0 0 20px;

                        div.mage-error[generated] {
                            position: absolute;
                            margin: 0;
                        }
                    }

                    &.choice-inline {
                        display: inline-block;
                        margin-right: 25px;
                        min-height: auto;
                        border: 1px solid $color-10;
                        padding: 0 15px;
                        border-radius: 5px;
                        height: 40px;
                        width: 29%;
                        line-height: 40px;

                        @media (max-width: 500px) {
                            display: block;
                            width: 87%;
                        }

                        .label {
                            color: $color-secondary;
                            font-size: rem(14);
                        }

                        div.mage-error[generated] {
                            position: absolute;
                        }
                    }

                    &.field-half {
                        width: 35%;
                        float: left;
                        min-height: 62px;

                        @media (max-width: 768px) {
                            width: 100%;
                            float: none;
                        }

                        .control {
                            width: 92%;

                            @media (max-width: 768px) {
                                width: 100%;
                            }
                        }
                    }

                    &.field-full {
                        width: 73%;
                        clear: both;

                        @media (max-width: 768px) {
                            width: 100%;
                            float: none;
                        }

                        .control {
                            width: 92%;

                            @media (max-width: 768px) {
                                width: 100%;
                            }
                        }

                        &.choice {
                            width: 100%;

                            .label {
                                font-size: rem(13);
                                line-height: 20px;
                                color: $color-secondary;
                                text-transform: uppercase;
                            }
                        }
                    }

                    &.captcha{
                        clear: both;
                    }

                    &.field-name-firstname,
                    &.field-name-lastname,
                    &.date.field-dob,
                    &.telephone,
                    &.fax,
                    &.taxvat,
                    &.gender {
                        width: 35%;
                        float: left;
                        min-height: 62px;

                        @media (max-width: 768px) {
                            width: 100%;
                            float: none;
                        }

                        .control {
                            width: 92%;

                            @media (max-width: 768px) {
                                width: 100%;
                            }
                        }
                    }

                    &.date.field-dob {
                        .control {
                            width: 92%;

                            input {
                                width: 100%;
                            }

                            button {
                                display: none;
                            }
                        }
                    }

                    &.zip {
                        position: relative;
                        margin-bottom: 15px;

                        .link-cep {
                            color: $color-primary;
                            text-transform: uppercase;
                            font-size: rem(10);
                            font-family: $font-secondary;
                            line-height: 15px;
                            text-decoration: underline;
                            position: absolute;
                            right: 30px;
                            bottom: -18px;

                        }
                    }

                    &.street {
                        width: 73%;
                        clear: both;
                        margin: 0px;

                        @media (max-width: 768px) {
                            width: 100%;
                        }

                        div.mage-error[generated] {
                            position: absolute;
                            margin: -24px 0 0;
                        }

                        .control {
                            width: 92%;

                            @media (max-width: 768px) {
                                width: 100%;
                            }

                            #street_1 {
                                margin-bottom: 20px;

                            }

                            .nested {
                                .additional {
                                    width: 38.3%;
                                    float: left;

                                    @media (max-width: 768px) {
                                        width: 100%;
                                        margin-bottom: 10px;
                                    }

                                    .control {
                                        width: 90%;

                                        @media (max-width: 768px) {
                                            width: 100%;
                                        }
                                    }

                                    &:first-child {
                                        width: 23.3%;

                                        @media (max-width: 768px) {
                                            width: 100%;
                                        }

                                        .control {
                                            width: 85%;

                                            @media (max-width: 768px) {
                                                width: 100%;
                                            }
                                        }
                                    }

                                    &:last-child {
                                        @media (max-width: 768px) {
                                            width: 100%;
                                            float: none;
                                        }

                                        .control {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.password{
                        .control{
                            .mage-error{
                                position: relative;
                                bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
