.account:not(.page-print){
    @media (max-width: 768px){
        .page-main{
            padding-top: 0;
        }
    }
    .sidebar-main{
        .block{
            background: $color-white;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            padding: 25px 30px;
            position: relative;

            .title{
                display: none;
            }

            .content{
                .nav {
                    &.item{
                        margin-bottom: 18px;

                        &:last-child{
                            margin-bottom: 0;
                        }

                        &.current{
                            strong{
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                text-transform: uppercase;
                                color: $color-primary;
                            }

                            &:after{
                                content: "";
                                background: $color-primary;
                                position: relative;
                                left: 0;
                                height: 3px;
                                width: 23px;
                                border-radius: 10px;
                                display: block;
                            }
                        }

                        a{
                            font-weight: 400;
                            font-size: rem(13);
                            line-height: 15px;
                            text-transform: uppercase;
                            color: $color-secondary;
                            letter-spacing: 0.03em;
                            font-family: $font-secondary;

                            &:hover{
                                text-decoration: none;
                                color: $color-primary;
                            }
                        }
                    }

                }
            }
        }
    }

    .column {
        &.main {
            width: 75.7%;
            background: $color-white;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            padding: 25px 30px;

            @media (max-width: 768px){
                padding: 25px 15px;
            }

            h2{
                color: $color-primary;
                font-size: rem(25);
                font-weight: 500;

                &:after{
                    content: "";
                    background: $color-primary;
                    position: relative;
                    left: 0;
                    height: 5px;
                    width: 25px;
                    border-radius: 10px;
                    display: block;
                    margin-top: 6px;
                }
            }

            .customer-name{
                color: $color-primary;
                font-size: rem(20);
                font-weight: 500;
                font-family: $font-secondary;
                margin-bottom: 1rem;
            }

            .block-text{
                margin-bottom: 2rem;

                p{
                    font-size: rem(16);
                }
            }

            .field.choice{
                margin: 0px 0 15px;
                min-height: auto;

                &.billing, &.shipping{
                    clear: both;
                }
            }

            .fieldset.info{
                margin: 0;
            }

            .block:not(.widget) {
                margin-bottom: 25px;

                .block-title {
                    margin-bottom: 1.5rem;

                    strong {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        text-transform: uppercase;
                        color: $color-primary;
                        font-family: $font-secondary;

                        &:after{
                            content: "";
                            background: $color-primary;
                            position: relative;
                            left: 0;
                            height: 3px;
                            width: 25px;
                            border-radius: 10px;
                            display: block;
                            margin-top: 6px;
                        }
                    }
                }

                .block-content{
                    .box{
                        background: $color-gray-2;
                        border-radius: 10px;
                        padding: 30px;
                        width: 41.2%;
                        min-height: 170px;

                        .box-title{
                             border-bottom: 1px solid #DADADA;
                             padding-bottom: 10px;
                             margin-bottom: 1.5rem;
                             display: block;

                            span{
                                font-weight: 500;
                                font-size: rem(16);
                                line-height: 22px;
                                text-transform: uppercase;
                                color: $color-primary;
                                font-family: $font-secondary;
                            }
                        }

                        .box-content{
                            margin-bottom: 1rem;

                            a{
                                color: $color-secondary;
                            }
                        }

                        .box-actions{
                            .action{
                                font-size: rem(12);
                                line-height: 35px;
                                font-weight: 500;
                                font-family: $font-secondary;
                                background: $color-primary;
                                color: $color-white;
                                border-radius: 7px;
                                padding: 0 20px;
                                text-align: center;
                                height: 35px;
                                display: inline-block;

                                &:first-child{
                                    margin-right: 20px;
                                }

                                &:hover,
                                &:focus {
                                    color: $color-white;
                                    background-color: lighten($color-primary, 9%);
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }

                &.block-dashboard-addresses{
                    position: relative;
                    margin-bottom: 2rem;

                    .action{
                        &.edit{
                            background: $color-primary;
                            border-radius: 7px;
                            height: 35px;
                            font-size: 12px;
                            line-height: 35px;
                            font-weight: 500;
                            text-align: center;
                            color: $color-white;
                            padding: 0 20px;
                            display: inline-block;
                            margin: 0;

                            &:hover,
                            &:focus {
                                color: $color-white;
                                background-color: lighten($color-primary, 9%);
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            .subtitle{
                font-weight: 500;
                font-size: rem(18);
                line-height: 22px;
                text-transform: uppercase;
                color: $color-primary;
                font-family: $font-secondary;
                margin: 0 0 1rem;

                &:after {
                    content: "";
                    background: $color-primary;
                    position: relative;
                    left: 0;
                    height: 3px;
                    width: 23px;
                    border-radius: 10px;
                    display: block;
                    margin-top: 6px;
                }
            }

            .fieldset{
                .legend{
                    margin: 0;

                    span{
                        font-weight: 500;
                        font-size: rem(18);
                        line-height: 22px;
                        text-transform: uppercase;
                        color: $color-primary;
                        font-family: $font-secondary;

                        &:after {
                            content: "";
                            background: $color-primary;
                            position: relative;
                            left: 0;
                            height: 3px;
                            width: 23px;
                            border-radius: 10px;
                            display: block;
                        }
                    }
                }

                .field{
                    &.field-half {
                        width: 50%;
                        float: left;
                        min-height: 65px;

                        @media (max-width: 768px){
                            width: 100%;
                        }
                    }

                    .control{
                        width: 94%;
                    }

                    &.date, &.type-document{
                        clear: both;
                    }

                    &.zip{
                        clear: both;
                        float: none;
                    }

                    &.addressnum{
                        width: 24.3%;
                    }

                    &.compl{
                        width: 24.3%;
                    }
                }

                .field-group{
                    .legend{
                        text-transform: uppercase;
                        color: $color-primary;
                        font-family: $font-secondary;
                        font-size: rem(12);
                        padding: 10px 0;
                    }

                    .field.choice-inline{
                        display: inline-block;
                        margin-right: 1rem;


                        .label{
                            font-size: rem(14);
                            margin: 0;
                        }
                    }
                }
            }

            .table-wrapper {
                background: #F9F9F9;
                border-radius: 3px;
                padding: 25px 30px 30px;

                .table {
                    tr {
                        th {
                            font-size: 14px;
                            text-transform: uppercase;
                            font-weight: 400;
                            color: $color-primary;
                        }

                        td {
                            font-size: 14px;
                            background: #fff;
                            padding: 13px 10px;
                            border-top: 6px solid #F9F9F9;
                            vertical-align: middle;

                            &.col{
                                &.id, &.status{
                                    font-weight: 700;
                                     text-transform: uppercase;
                                }

                                &.actions{
                                    text-align: center;
                                    text-transform: uppercase;

                                    .view{
                                        font-size: 12px;
                                        line-height: 11px;
                                        text-align: center;
                                        text-transform: uppercase;
                                        font-weight: 700;
                                        color: $color-primary;
                                        border: 3px solid $color-primary;
                                        border-radius: 50px;
                                        display: inline-block;
                                        padding: 7px 12px;

                                        &:hover{
                                            text-decoration: none;
                                            color: $color-white;
                                            background: $color-primary;
                                        }
                                    }
                                }

                                &.price,{
                                    span{
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                }

                                &.subtotal {
                                    span {
                                        font-size: 14px;
                                         color: $color-primary;
                                    }
                                }
                            }
                        }
                    }

                    tfoot{
                        td, th{
                            background: none;
                            border: none;
                        }

                        th{
                            color: $color-1;
                        }

                         td{
                             color: $color-primary;
                            text-align: left;
                         }
                    }
                }
            }

            .actions-toolbar{
                margin-top: 1rem;

                .primary{
                    .action{
                    }
                }

                .secondary{
                    .back{
                        display: none;
                    }
                }
            }
        }

        .order-title{
            position: absolute;
            top: -40px;

            h2{
                 color: $color-secondary;
                 font-size: 24px;
                 line-height: 30px;
                 text-transform: uppercase;

                 span{
                      color: $color-primary;
                 }
            }
        }

        .order-details-items{
            &.ordered {
                border: none;

                @media (min-width: 768px){
                    .order-title {
                        display: none;
                    }
                }

                .table-order-items {
                    tr{
                        th{
                            border-bottom: 1px solid #E5E5E5;
                        }

                        td{
                            background: none;
                            border-bottom: 1px solid #E5E5E5;
                            border-top: none;

                            .product-item-name {
                                color: $color-primary;
                            }
                        }
                    }

                    tfoot{
                        th, td{
                            border: none;
                        }

                        .subtotal{
                            border-top: 1px solid #E5E5E5;
                        }
                    }
                }
            }
        }

        .order-actions-toolbar{
            float: right;
        }
    }
}

@media (min-width: 768px){
    .account.page-layout-2columns-left {
        .column{
            &.main {
                width: 75.7%;
                margin-bottom: 3rem;
            }
        }

        .sidebar-main {
            width: 23.3%;
        }
    }
}

.sales-order-view{
    .column.main{
        position: relative;

        .order-date {
            margin-top: 80px;
        }
    }
}

.account-new-patient{
    position: relative;

    .btn-back{
        color: $color-4;
        border: 2px solid $color-4;
        border-radius: 7px;
        font-weight: 500;
        font-size: rem(12);
        line-height: 14px;
        font-family: $font-secondary;
        height: 31px;
        line-height: 31px;
        padding: 0 17px;
        display: block;
        float: right;
        margin-top: -50px;

        &:hover{
            text-decoration: none;
            color: $color-white;
            background: $color-4;
        }
    }
}

.account-list-patient{
    .btn-add-new{
        float: right;

        button.action.primary{
            font-size: rem(12);
            padding: 3px 15px;
            margin-top: -70px;
        }
    }

    #accordionPatients{
        .accordion-title{
            border-bottom: 1px solid #E5E5E5;
            padding: 15px 0;
            position: relative;
            cursor: pointer;
            box-shadow: none;

            &:after{
                content: "";
                width: 13px;
                height: 17px;
                position: absolute;
                right: 15px;
                top: 13px;
                z-index: 0;
            }

            .accordion-trigger{
                position: relative;
                z-index: 2;
            }

            span{
                color: $color-primary;
                font-size: rem(16);
                font-family: $font-secondary;
                display: block;
                position: relative;
            }
        }

        .accordion-content{
            padding: 10px 0;
            display: none;
            box-shadow: none;

            .action.view{
                color: $color-white;
                border: 2px solid $color-primary;
                background: $color-primary;
                border-radius: 7px;
                font-weight: 500;
                font-size: rem(12);
                line-height: 14px;
                font-family: $font-secondary;
                height: 31px;
                line-height: 31px;
                padding: 0 17px;
                display: inline-block;
                margin-right: 1rem;

                &:hover{
                    text-decoration: none;
                    color: $color-white;
                    background: $color-primary;
                }
            }

            .action.remove{
                color: $color-primary;
                border: 2px solid $color-primary;
                border-radius: 7px;
                font-weight: 500;
                font-size: rem(12);
                line-height: 14px;
                font-family: $font-secondary;
                height: 31px;
                line-height: 31px;
                padding: 0 17px;
                display: inline-block;

                &:hover{
                    text-decoration: none;
                    color: $color-white;
                    background: $color-primary;
                }
            }
        }
    }
}

.form-address-edit {
    .actions-toolbar {
        .action.primary{
            padding: 6px 20px;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.6rem;
        }
    }
}
