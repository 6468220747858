.block-locations{
    padding: 2rem 0;
    clear: both;

    @media (max-width: 768px) {
        padding: 2rem 0;
    }

    h2{
        font-weight: 500;
        font-size: rem(25);
        line-height: 30px;
        color: $color-primary!important;
        margin: 0 auto 4rem;
        text-align: center;
    }

    .filter-locations{
        .fieldset{
            @include flexbox;
            @include justify-content(flex-start);
            padding: 0 10px;

            @media (max-width: 768px) {
                flex-direction: column;
                padding: 0;
            }

            .field{
                width: 30%;

                @media (max-width: 768px) {
                    width: 100%;
                }

                &:first-child{
                    margin-right: 1rem;
                }

                .label{
                    background: $color-gray-2;
                }

                .control{
                    width: 100%;
                }
            }
        }
    }

    .list-locations{
        &.grid{
            @include flexbox;
            flex-wrap: wrap;
        }

        &.slider-locations{
            .list-locations-item{
                margin-bottom: 0;
            }
        }

        .list-locations-item{
            padding: 0 10px;
            margin-bottom: 3rem;
            max-width: 350px;
            width: 100%;

            @media (max-width: 480px){
                max-width: none;
            }

            figure{
                width: 100%;
                border-radius: 7px;
                margin-bottom: 1rem;
                height: 192px;

                @media (max-width: 480px){
                    max-width: 350px;
                    margin: 0 auto 1rem;
                }

                img{
                    border-radius: 7px;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            h3{
                font-weight: 500;
                font-size: rem(22);
                line-height: 30px;
                color: $color-primary;
                margin: 0 0 0.5rem;

                a{
                    color: $color-primary;
                }
            }

            p{
                font-size: rem(14);
                text-transform: uppercase;
                margin: 0 0 1rem;
            }

            .btn{
                border: 2px solid $color-primary;
                border-radius: 7px;
                color: $color-primary;
                font-weight: 500;
                font-size: rem(12);
                font-family: $font-secondary;
                height: 31px;
                line-height: 31px;
                padding: 0 15px;
                display: inline-block;
                background-color: $color-white;

                &:hover{
                    color: $color-white;
                    text-decoration: none;
                    background-color: $color-primary;
                }
            }
        }
    }
}

.home-block-locations{
    background: $color-white;
    padding: 0 0 1rem;

    .btn-readmore{
        background: $color-primary;
        color: $color-white;
        border-radius: 7px;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 14px;
        height: 35px;
        line-height: 35px;
        padding: 0 20px;
        float: right;
        position: relative;
        top: 4rem;

        @media (max-width: 767px){
            top: 6.5rem;
            margin: 0 auto;
            display: block;
            text-align: center;
            max-width: 55px;
            float: none;
        }

        &:hover{
            text-decoration: none;
            background: lighten($color-primary, 10%);
        }
    }
}

.cms-unidades{
    .block-locations{
        padding: 1rem 0;
        clear: both;
    }
}

.catalog-product-view{
    .block-locations{
        padding: 2rem 0 0;
        clear: both;

        @media (max-width: 768px) {
            padding: 0;
        }
    }
}

.unidades-view-index{
    .page-title-wrapper{
        display: none;
    }

    .primary-outline{
        margin: 20px 0 0;
        display: block;
        max-width: 75px;
        width: 100%;
        text-align: center;
        padding: 6px 10px;
    }

    .content-locations{
        padding: 20px 0;

        .wrap{
            @include flexbox;

            @media (max-width: 768px){
                flex-wrap: wrap;
                @include flex-direction(column);
            }

            .location-gallery{
                max-width: 540px;
                width: 100%;
                margin-right: 30px;

                @media (max-width: 768px){
                    margin-bottom: 20px;
                }

                figure{
                    border-radius: 7px;

                    img{
                        border-radius: 7px;
                        width: 100%;
                    }
                }
            }

            .action.primary-outline-red{
                display: inline-block;
                font-weight: 500;
                font-size: rem(12);
                padding: 3px 19px;
                margin-top: 5px;
            }

            .location-info{
                h2{
                    color: $color-primary;
                    font-weight: 500;
                    font-size: rem(24);
                    line-height: 30px;
                    position: relative;

                    &:after{
                        content: "";
                        width: 23px;
                        height: 3px;
                        background: $color-primary;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        border-radius: 30px;
                    }
                }

                address{
                    display: block;
                    margin-bottom: 15px;
                }

                h3{
                    color: $color-primary;
                    font-weight: 500;
                    font-size: rem(24);
                    line-height: 30px;
                    margin: 30px 0 15px;
                }
            }
        }
    }
}
