.custom-checkout {
    margin-top: 2rem;

    .page-title-wrapper{
        padding: 30px;
        position: relative;
        box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.07);
        border-radius: 7px 7px 0 0;
        background: $color-white;
        text-align: left!important;

        &:before{
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            background: $color-primary;
            width: 10px;
            height: 55px;
            transform: translate(0, -50%);
        }

        .page-title{
            color: $color-primary;
            margin: 0 0 2px;
        }

        p{
            margin: 0;
        }
    }

    .wrap{
        .custom-checkout-content{
            padding: 30px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
            background: $color-white;
            position: relative;
            min-height: 400px;

            @media (max-width: 768px){
                padding: 30px 15px 15px;
                min-height: auto;
            }

            .buttons{
                a{
                    margin-right: 3%;

                    @media (max-width: 400px){
                        display: block;
                        margin-bottom: 15px;
                        margin-right: 0;
                    }
                }
            }

            .product-title{
                font-size: rem(20);
                color: $color-primary;
                margin: 0 0 0.9rem;
            }

            .progress-bar{
                position: relative;
                margin-bottom: 1.5rem;

                .line{
                    @include flexbox;
                    @include justify-content(space-between);
                    @include align-items(center);
                    margin: 0 0 0.4rem;
                    width: 70px;
                    position: relative;

                    &:before{
                        content: "";
                        position: absolute;
                        top: 6px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background: #E9E9E9;
                        z-index: 1;
                    }

                    span{
                        width: 6px;
                        height: 6px;
                        border-radius: 100%;
                        background: #E9E9E9;
                        font-size: 0;
                        display: block;
                        position: relative;
                        z-index: 2;
                        border: 2px solid $color-white;

                        &.active{
                            background: $color-primary;
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                small{
                font-size: rem(16);
                display: block;
                }
            }

            .legend{
                color: $color-primary;
                font-size: rem(16);
                display: block;
                margin-bottom: 1rem;
                margin-left: 0;
                clear: both;
                float: none;
            }

            .fieldset{
                margin-bottom: 2rem;

                .field-group{
                    .choice-inline{
                        display: inline-block;
                        margin-right: 2rem;
                        margin-bottom: 0;
                    }

                    .choice{
                        margin-bottom: 5px;
                    }

                    &.custom-radio {
                        @include flexbox;
                        @include justify-content(space-between);
                        flex-wrap: wrap;
                        max-width: 370px;

                        .field.choice{
                            margin-bottom: 8px;

                            label {
                                display: inline-block;
                                background-color: #F5F5F5;
                                padding: 6px 0;
                                font-family: $font-secondary;
                                font-size: rem(13);
                                border: none;
                                border-radius: 5px;
                                width: 53px;
                                text-align: center;
                                cursor: pointer;
                            }

                            input[type="radio"] {
                                opacity: 0;
                                position: fixed;
                                width: 0;

                                &:checked + label {
                                    background-color:$color-primary;
                                    color: $color-white;
                                }

                                &:focus + label {
                                    background-color:$color-primary;
                                    color: $color-white;
                                }
                            }
                        }

                    }
                }
            }

            .field-radio{
                clear: both;
            }

            .field-checkbox{
                clear: both;
                padding: 1rem 0;

                .label{
                    text-transform: uppercase;
                    font-size: rem(13);
                }
            }

            .field.date .control{
                &:after{
                    content: '';
                    background: url($img-path + "icons/icon-calendar.svg") no-repeat 0 0;
                    display: block;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 9px;
                    right: 65px;
                    background-size: contain;
                }
            }
        }

        .custom-checkout-footer{
            padding: 30px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
            background: $color-white;
            position: relative;
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
            border-radius: 0 0 7px 7px;

            @media (max-width: 768px){
                padding: 30px 15px;
                @include flex-direction(column);

                .action{
                    margin-bottom: 15px;
                }
            }
        }
    }

    .msg-note{
        padding: 15px;
        border: 1px solid #D1D1D1;
        position: absolute;
        right: 2rem;
        max-width: 350px;

        @media (max-width: 768px){
            position: relative;
            right: 0;
            margin-bottom: 1rem;
        }

        p{
            font-size: 14px;
            color: #7D7D7D;
            margin-bottom: 10px;

            @media (max-width: 768px){
                font-size: 13px;
                line-height: 18px;
            }
        }
    }
}
