.cms-noroute-index {
    background: $color-primary;

    .page-header,
    .nav-sections,
    footer {
        display: none;
    }

    .columns {
        .column.main {
            padding-bottom: 0;
        }
    }

    .page-error {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);

        @media (max-width: 991px) {
            @include flex-direction(column);
        }

        height: 100vh;

        &-left {
            width: 50%;

            @media (max-width: 991px) {
                width: 100%;
                margin-bottom: 2rem;
                text-align: center;
            }

            img {
                max-width: 300px;
                width: 100%;

                @media (max-width: 991px) {
                    max-width: 140px;
                }
            }
        }

        &-right {
            width: 37%;

            @media (max-width: 991px) {
                width: 100%;
                text-align: center;
            }

            h1 {
                font-weight: 500;
                font-size: rem(40);
                line-height: 1;
                color: $color-white;
                text-transform: uppercase;
                position: relative;
                margin: 0 0 3rem;

                @media (max-width: 991px) {
                    font-size: rem(30);
                }

                &:after {
                    content: "";
                    width: 50px;
                    height: 10px;
                    background: $color-white;
                    position: absolute;
                    bottom: -25px;
                    left: 0;
                    border-radius: 30px;

                    @media (max-width: 991px) {
                        right: 0;
                        margin: 0 auto;
                    }
                }
            }

            p {
                font-size: rem(16);
                line-height: 25px;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                color: $color-white;

                @media (max-width: 991px) {
                   font-size: rem(14);
                }
            }

            a.action.primary{
                background: $color-4;
                border-color: $color-4;
            }
        }
    }
}
