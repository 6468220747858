.block-search {
    position: relative;
    width: 100%;
    z-index: 2;
    max-width: 540px;
    float: none;
    padding: 0;
    clear: both;

    .label{
        display: none;
    }

    input {
        margin: 0;
        height: 50px;
        border-radius: 7px;
        border: 1px solid transparent;
        padding: 0 20px;
        width: 100%;
        color: $color-secondary;
        font-size: rem(13);
        font-family: $font-secondary;
        background: $color-white;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

        &::placeholder {
            opacity: 1;
            color: $color-secondary;
        }

        @media (max-width: 768px) {
            left: 0;
            margin: 0;
            position: relative;
        }

    }

    .control {
        margin: 0;
        padding: 0;
        border: none;
        position: relative;
    }

    .actions {
        .action.search {
            opacity: 1;
            top: 8px;
            background: none;
            position: absolute;
            right: 10px;
            border: none;
            padding: 5px 0;

            @media (max-width: 768px) {
                display: inline-block;
            }

            &:before {
                color: $color-primary;
                font-size: 24px;
                line-height: 24px;
                content: '\e615';
                font-family: 'icons-blank-theme';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }

            > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }
    }

    .search-autocomplete{
        background: $color-white;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
        border-radius: 10px;

        ul{
            li{
                border: none;
                padding: 5px 45px 5px 20px;

                &.selected{
                    background: none;

                    span{
                        color: $color-primary;
                    }
                }

                span{
                    color: $color-secondary;
                    text-transform: uppercase;
                    font-size: rem(13);
                }
            }
        }
    }

    .link{
        font-size: 13px;
        line-height: 15px;
        color: $color-secondary;
        text-transform: uppercase;
        font-family: $font-secondary;
        float: right;
        position: relative;
        top: 5px;

        @media (max-width: 768px) {
            display: none;
        }

        &:hover{
            color: $color-primary;
        }
    }
}

.nav-search{
    float: left;
    margin-left: 20px;

    @media (max-width: 768px) {
        margin-left: 0;
    }

    a{
        background: $color-3;
        color: $color-primary;
        text-transform: uppercase;
        font-size: rem(13);
        font-family: $font-secondary;
        border-radius: 5px 5px 0px 0px;
        padding: 5px 10px;
        display: inline-block;

        &.active{
            background: $color-primary;
            color: $color-3;
        }

        &:hover{
            text-decoration: none;
            background: $color-primary;
            color: $color-3;
        }
    }
}
