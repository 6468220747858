.page-footer {
    background: $color-2 url($img-path + "bg-footer-triangle.svg") no-repeat 95% top;

    @media (max-width: 768px) {
        background: $color-2;
    }

    .footer {
        a {
            color: $color-secondary;
            text-decoration: none;

            &:hover {
                color: $color-secondary;
                text-decoration: underline;
            }
        }

        &.content {
            border: none;
            padding-bottom: 0;

            @media (max-width: 768px) {
                padding-top: 0;
            }

            .footer-logo {
                margin: 0 auto 3rem;
                max-width: 220px;

                @media (max-width: 768px) {
                    margin: 0 auto 1.5rem;
                    max-width: 140px;

                    img {
                        max-width: 140px;
                    }
                }
            }

            .footer-top {
                .row {
                    @include flexbox;
                    @include justify-content(space-between);
                    margin-bottom: 2.5rem;

                    @media (max-width: 768px) {
                        @include flex-wrap(wrap);
                        @include flex-direction(column);
                        margin-bottom: 0;
                    }
                }

                .footer-title {
                    color: $color-primary;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    font-family: $font-secondary;
                    text-transform: uppercase;
                    position: relative;
                    margin-bottom: 35px;

                    &:after {
                        content: "";
                        width: 20px;
                        height: 5px;
                        background: $color-primary;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        border-radius: 30px;

                        @media (max-width: 767px) {
                            right: 0;
                        }
                    }
                }

                p{
                    font-size: 14px;
                    line-height: 16px;
                    font-family: $font-secondary;
                    letter-spacing: 0.03em;
                    margin: 0 0 10px;
                }

                .footer-menu {
                    @include flexbox;
                    @include justify-content(space-between);
                    padding-right: 2rem;
                    flex: 0 0 72%;

                    @media (max-width: 768px) {
                       display: none;
                    }

                    .footer-links-block {
                        .footer.links {
                            columns: 3;
                            column-gap: 0;
                            padding: 0 0px 0 0;
                            margin: 0;

                            @media (max-width: 768px) {
                                text-align: center;
                                margin: 0 auto 2rem;
                            }
                        }

                        .footer.links {
                            li {
                                a {
                                    color: $color-secondary;
                                    font-size: rem(14);
                                    font-family: $font-secondary;
                                }
                            }
                        }
                    }
                }

                .footer-contact{
                    @media (max-width: 768px) {
                        margin: 0 0 1.5rem;
                    }
                }

                .footer-social {
                    flex: 0 0 25%;

                    @media (max-width: 768px) {
                        margin: 0 0 1.5rem;
                        flex: 0 0 100%;
                    }

                    .footer-title{
                        display: inline-block;
                        margin: 0 10px 0 0;

                        @media (max-width: 768px) {
                            display: block;
                            margin: 0 0 35px;
                        }

                        &:after{
                            display: none;

                            @media (max-width: 768px) {
                                display: block;
                            }
                        }
                    }

                    ul{
                        display: inline-block;

                        li {
                            display: inline-block;

                            a {
                                display: inline-block;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center center;
                                width: 20px;
                                height: 20px;
                                margin-right: 15px;
                                vertical-align: middle;
                                font-size: 0;

                                &.facebook {
                                    background-image: get-icon('facebook', $color-secondary);
                                }

                                &.instagram {
                                    background-image: get-icon('instagram', $color-secondary);
                                }

                                &.youtube {
                                    background-image: get-icon('youtube', $color-secondary);
                                }

                                &.twitter {
                                    background-image: get-icon('twitter', $color-secondary);
                                }
                            }
                        }
                    }
                }

                .footer-payments {
                    padding-right: 2rem;
                    flex: 0 0 72%;

                    @media (max-width: 768px) {
                        padding-right: 0;
                        margin-bottom: 1.5rem;
                        flex: 0 0 100%;
                    }

                    img{
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 17px;


                        @media (max-width: 768px) {
                            margin-bottom: 10px;
                            max-width: 36px;
                            margin-right: 8px;
                        }
                    }
                }

                .footer-seals {
                    flex: 0 0 25%;

                    @media (max-width: 767px) {
                        flex: 0 0 100%;
                        margin-bottom: 1.5rem;
                    }
                }

                .footer-info {
                    padding-right: 2rem;
                    flex: 0 0 72%;

                    @media (max-width: 768px) {
                        flex: 0 0 100%;
                        padding-right: 0;
                        margin-bottom: 1.5rem;
                    }

                    p {
                        font-size: rem(13);
                        line-height: 20px;
                        letter-spacing: 0;
                        font-family: $font-primary;
                        margin: 0;
                    }

                    strong {
                        color: $color-secondary;
                    }
                }
            }
        }
    }

    .footer-copyright {
        background: $color-1;
        padding: 1rem 0;

        @media (min-width: 768px) {
            .container {
                @include flexbox;
                @include align-items(center);
                @include justify-content(space-between);
            }
        }

        small {
            color: $color-secondary;
            font-size: rem(13);
            line-height: 20px;
            text-transform: uppercase;

            @media (max-width: 768px) {
                text-align: center;
                display: block;
                font-size: rem(10);
                line-height: 15px;
            }
        }

        .by {
            span {
                color: $color-secondary;
                font-size: rem(10);
                letter-spacing: 0.1em;
                line-height: 16px;
                text-transform: uppercase;
                display: inline-block;
                vertical-align: middle;
            }

            @media (max-width: 767px) {
               margin: 8px auto 0;
                display: block;
                text-align: center;
            }
        }
    }

    .block {
        &.newsletter {
            max-width: 100%;
            width: 100%;
            background: $color-secondary;
            padding: 30px 0;

            .content {
                padding-left: 15px;
                padding-right: 15px;

                @media (min-width: 768px) {
                    box-sizing: border-box;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 1140px;
                    padding-left: 20px;
                    padding-right: 20px;
                    width: auto;
                    @include flexbox;
                    @include align-items(center);
                    @include justify-content(space-between);
                }

                .title {
                    display: block;
                    vertical-align: middle;
                    color: $color-white;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    margin-right: 15px;
                    margin-bottom: 0;

                    @media (max-width: 767px) {
                        margin: 0 auto 20px;
                        text-align: center;
                    }

                    span {
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 1.5;
                    }

                    strong {
                        font-size: rem(20);
                        display: block;
                        margin: 0;
                    }

                    &:before {
                        content: "";
                        background-image: url($img-path + "splash-mail.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 105px;
                        height: 110px;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 20px;

                        @media (max-width: 767px) {
                            width: 70px;
                            height: 80px;
                        }
                    }
                }

                .subscribe {
                    width: auto;
                    flex: 0 1 64%;

                    @media (max-width: 767px) {
                        flex: 0 1 100%;
                        display: block;
                    }

                    .field {
                        @media (min-width: 768px) {
                            @include flexbox;
                            @include align-items(center);
                        }

                        .control {
                            max-width: 255px;
                            width: 100%;
                            margin-right: 30px;

                            @media (max-width: 767px) {
                                margin: 0 auto 10px;
                            }

                            &:before {
                                display: none;
                            }

                            label {
                                color: $color-white;
                                font-size: rem(13);
                                font-weight: 300;
                            }

                            input[type="text"],
                            input[type="email"] {
                                border: none;
                                border-bottom: 3px solid $color-white;
                                background: transparent;
                                color: $color-white;
                                font-size: rem(16);
                                width: 100%;
                                padding: 0;
                                margin: 0;
                                height: 35px;
                                transition: all 0.2s ease;

                                &::placeholder {
                                    color: $color-white;
                                    font-weight: 300;
                                }

                                &:focus{
                                    border-color: $color-primary;
                                }
                            }
                        }
                    }

                    .actions {
                        vertical-align: bottom;

                        @media (max-width: 767px) {
                            display: block;
                            margin: 0 auto;
                            width: auto;
                            text-align: center;
                        }

                        .subscribe {
                            border-radius: 50px;
                            border: 2px solid $color-white;
                            font-size: 14px;
                            font-weight: 700;
                            height: 40px;
                            padding: 0 25px;
                            background: $color-secondary;

                            @media (max-width: 768px) {
                                margin: 20px auto 0;
                                text-align: center;
                            }

                            &:hover{
                                background: lighten($color-secondary, 10);
                            }
                        }
                    }
                }
            }
        }
    }
}
