.customer-account-forgotpassword,
.customer-account-createpassword {
    background: $color-gray-2;

    .column.main{
        background: $color-white;
        border-radius: 10px;
        padding: 3rem 4rem;
        width: 100%;
        max-width: 540px;
        margin: 3rem auto 6rem;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

        @media (max-width: 991px) {
            padding: 30px;
            width: auto;
            min-height: auto;
            margin: 1rem auto 2rem;
        }

        .page-title-wrapper{
            margin: 0 0 2rem;
            text-align: left;
        }

        .form.password.forget{
            width: 100%;
            min-width: auto;

            .field.note{
                padding-right: 1rem;

                @media (max-width: 991px) {
                    padding-right: 0;
                }
            }

            .control{
                width: 100%;
            }

            .actions-toolbar {
                margin: 3rem 0 0;

                .primary{
                    float: none;
                }
            }
        }

        .page-title {
            font-weight: 500;
            font-size: rem(30);
            line-height: 40px;
            color: $color-primary;
            border: none;
            position: relative;
            margin-bottom: 2.5rem;
            padding-bottom: 5px;

            @media (max-width: 991px) {
                font-size: 25px;
                line-height: 35px;
            }

            &:after {
                content: "";
                width: 30px;
                height: 5px;
                background: $color-primary;
                position: absolute;
                bottom: -6px;
                left: 0;
                border-radius: 30px;
            }

            strong {
                font-weight: 600;
            }
        }
    }
}

@media (min-width: 768px){
    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account,
    .form.search.advanced,
    .form.form-orders-search {
        min-width: auto;
        width: auto;
    }
}
