/** titles **/

h1 {
    font-size: rem(30);
    line-height: 1.2;
    font-family: $font-secondary;
    margin: 0 0 2rem;
    font-weight: 500;

    @media (max-width: 991px) {
        font-size: rem(26);
    }
}

h2{
    font-size: rem(24);
    line-height: 1.2;
    margin-bottom: 20px;
    font-family: $font-secondary;
    margin: 0 0 2rem;
    font-weight: 500;

    @media (max-width: 991px) {
        font-size: rem(22);
    }
}

h3 {
    font-size: rem(22);
    line-height: 1.2;
    font-family: $font-secondary;
    margin: 0 0 2rem;
    font-weight: 500;

    @media (max-width: 991px) {
        font-size: rem(18);
    }
}

h4 {
    font-size: rem(18);
    line-height: 1.2;
    font-family: $font-secondary;
    margin: 0 0 1rem;
    font-weight: 400;
}

h5 {
    font-size: rem(16);
    line-height: 1.2;
    font-family: $font-secondary;
    margin: 0 0 1rem;
}

h6 {
    font-size: rem(14);
    line-height: 1.2;
    font-family: $font-primary;
    margin: 0 0 1rem;
}

small {
    font-size: rem(12);
    font-weight: normal;
    font-style: normal;
    line-height: 1.36;
}

p {
    font-size: $font-size;
    margin-bottom: 1.5rem;
    line-height: 1.5;

    @media (max-width: 991px) {
        font-size: rem(14);
        line-height: 22px;
    }
}

.cms-page-view {
    &.page-layout-1column{
        h2{
            font-weight: 500;
            font-size: rem(24);
            line-height: 1.2;
            color: $color-secondary;
            position: relative;
            margin: 0 0 3rem;

            @media (max-width: 991px) {
                font-size: rem(20);
            }
        }

        h3{
            color: $color-primary;
            font-size: rem(22);
            font-weight: 500;
            margin: 0 0 2rem;

            @media (max-width: 991px) {
                font-size: rem(18);
            }
        }
    }
}

.category-cms{
    h2{
        font-weight: 500;
        font-size: rem(24);
        line-height: 1.2;
        color: $color-secondary;
        position: relative;
        margin: 0 0 3rem;

        @media (max-width: 991px) {
            font-size: rem(20);
        }
    }

    h3{
        color: $color-primary;
        font-size: rem(22);
        font-weight: 500;
        margin: 0 0 2rem;

        @media (max-width: 991px) {
            font-size: rem(18);
        }
    }
}
