/*@function parseInt($n) {
    @return $n / ($n * 0 + 1);
}*/

// =============================================================================
// Convert PX to REM
// =============================================================================

@function rem($value) {
    $remValue: calc($value / 16) + rem;
    @return $remValue;
}

@function v-url($url) {
    $v-url: url($url + "?v=" + $version);

    @return $v-url;
}

@mixin ellipsis-multiline($line-height, $lines: 2, $set-height: true) {
    @if $set-height==true {
        height: $line-height * $lines;
    }

    @else {
        max-height: $line-height * $lines;
    }

    display: block;
    overflow: hidden;
    line-height: $line-height;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    text-overflow: -o-ellipsis-lastline;
}

@mixin use-sprite($sprite, $width: true) {
    $url: #{nth($sprite, 9)};
    $factor: 1px;

    @if $width !=true {
        $factor: ($width + $sprite-padding) * 100 / nth($sprite, 5) / 100;
    }

    display: inline-block;
    vertical-align: middle;
    width: parseInt(nth($sprite, 5) + $sprite-padding) * $factor;
    height: parseInt(nth($sprite, 6) + $sprite-padding) * $factor;
    background-position: (parseInt(nth($sprite, 3)) * $factor) (parseInt(nth($sprite, 4)) * $factor);
    background-size: (parseInt(nth($sprite, 7)) * $factor) auto;
    background-image: v-url($url);
    background-repeat: no-repeat;
}

// =============================================================================
// Font Face
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
        font-display: swap;
	}
}

// =============================================================================
// Flexbox
// =============================================================================

@mixin flexbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin flexbox-inline {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

@mixin flex-direction($value: row) {
    @if $value==row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
    }

    @else if $value==column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
    }

    @else if $value==column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
    }

    @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
    }

    -webkit-flex-direction: $value;
    -moz-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
    // No Webkit Box fallback.
    -webkit-flex-wrap: $value;
    -moz-flex-wrap: $value;

    @if $value==nowrap {
        -ms-flex-wrap: none;
    }

    @else {
        -ms-flex-wrap: $value;
    }

    flex-wrap: $value;
}

@mixin flex-grow($int: 0) {
    -webkit-box-flex: $int;
    -webkit-flex-grow: $int;
    -moz-flex-grow: $int;
    -ms-flex-positive: $int;
    flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
    -webkit-flex-shrink: $int;
    -moz-flex-shrink: $int;
    -ms-flex-negative: $int;
    flex-shrink: $int;
}

@mixin justify-content($value: flex-start) {
    @if $value==flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    }

    @else if $value==flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    }

    @else if $value==space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    }

    @else if $value==space-around {
        -ms-flex-pack: distribute;
    }

    @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }

    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
}

@mixin align-items($value: stretch) {
    @if $value==flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
    }

    @else if $value==flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
    }

    @else {
        -webkit-box-align: $value;
        -ms-flex-align: $value;
    }

    -webkit-align-items: $value;
    -moz-align-items: $value;
    align-items: $value;
}

// =============================================================================
// CSS3 Prefix
// Example: @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
// =============================================================================

@mixin css3-prefix($prop, $value) {
    -webkit-#{$prop}: #{$value};
    -moz-#{$prop}: #{$value};
    -ms-#{$prop}: #{$value};
    -o-#{$prop}: #{$value};
    #{$prop}: #{$value};
}
