.page-layout-home-initial {
    background: $color-2;

    .columns .column.main {
        padding-bottom: 0;
    }

    .page-wrapper{
        &:after{
            content: "";
            background: url($img-path + "bg-gray-curve.svg") no-repeat 0 0;
            background-size: contain;
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 570px;
            width: 100%;
            height: 336px;
            z-index: -1;

            @media (max-width: 480px){
                display: none;
            }
        }
    }

    .home-initial-content{
        @media (max-width: 480px){
            transform: translate(0, -50%);
            position: absolute;
            top: 50%;
        }

        .header-logo{
            margin: 5rem auto;
            text-align: center;
            max-width: 250px;

            @media (max-width: 480px){
                margin: 1rem auto 3rem;
                max-width: 210px;
            }
        }

        .wrap{
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);

            .col-right{
                @media (max-width: 480px){
                    display: none;
                }
            }

            .col-left{
                max-width: 460px;

                h2{
                    font-weight: 500;
                    font-size: rem(30);
                    line-height: 30px;
                    color: $color-primary;
                    margin: 0 0 1.2rem;
                }

                p{
                    font-size: rem(18);
                    line-height: 23px;
                    font-weight: 500;
                    color: $color-4;
                    font-family: $font-secondary;
                    padding-right: 3rem;
                    margin: 0 0 1.5rem;

                    @media (max-width: 480px){
                        padding-right: 0;
                    }

                    strong{
                        color: $color-primary;
                    }
                }

                .form{
                    .store-dropdown{
                        select{
                            background: $color-white;
                            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
                            border-radius: 7px;
                            display: inline-block;
                            border-color: $color-white;
                            width: 295px;
                            margin-right: 15px;

                            @media (max-width: 480px){
                                width: 100%;
                                margin-right: 0;
                            }

                            &#state-change{
                                margin-bottom: 20px;
                            }

                            &#city-change{
                                display: none;
                            }
                        }

                        .btn-continue{
                            display: inline-block;
                            padding: 5px 20px;

                            @media (max-width: 480px){
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }
    }


}
