#notice-cookie-block {
    &.message.global.cookie{
        background: $color-4;
        border: none;
        max-width: 100%;
        z-index: 100;

        .content{
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);

            @media (max-width: 991px){
                @include flex-direction(column);
            }

            p{
                color: $color-white;
                font-size: rem(15);
                max-width: 87%;

                @media (max-width: 991px) {
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: rem(14);
                    line-height: 1.3;
                    max-width: 100%;
                }

                a{
                    color: $color-white;
                }
            }

            .actions{
                margin: 0;

                button.action.primary{
                    font-size: rem(12);
                    padding: 10px 20px;
                    border: none;
                    line-height: 1.3;
                }
            }
        }
    }
}
