.cart-container {
    .form-cart {
        background: $color-white;
        margin-bottom: 35px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        .actions {
            padding: 10px 30px 30px;

            .action {
                &.clear {
                    display: none;
                }

                &.update {
                    background: $color-white;
                    color: $color-primary;
                    font-size: rem(16);
                    font-weight: 400;
                    border-radius: 7px;
                    border-color: $color-primary;
                    margin: 0;
                    padding: 6px 20px;

                     @media (max-width: 768px) {
                         margin: 0 auto;
                     }

                    &:before {
                        content: "";
                        width: 15px;
                        height: 15px;
                        display: inline-block;
                        vertical-align: middle;
                        background-image: get-icon('reload', $color-primary);
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                        margin-right: 5px;
                    }

                    &:hover {
                        background: $color-primary;
                        color: $color-white;
                        border-color: $color-primary;

                        &:before {
                            background-image: get-icon('reload', $color-white);
                        }
                    }
                }

                &.continue {
                    font-family: $font-secondary;
                    font-size: rem(16);
                    font-weight: 400;
                    border-radius: 7px;
                    background: $color-primary;
                    color: $color-white;
                    text-align: center;
                    border: 2px solid $color-primary;
                    padding: 6px 15px;

                    &:hover, &:focus {
                        color: $color-white;
                        background-color: lighten($color-primary, 9%);
                        border: 2px solid lighten($color-primary, 9%);
                    }

                    @media (max-width: 768px) {
                        margin-bottom: 10px;
                    }

                    &:before {
                        content: "";
                        width: 21px;
                        height: 21px;
                        display: inline-block;
                        vertical-align: middle;
                        background: url($img-path + "icons/icon-cart-solid.svg");
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            padding: 0 30px;

            @media (max-width: 768px) {
               padding: 0 15px;
               width: auto;
            }

            .cart {
                &.items {
                    thead+.item {
                        border-top: 1px solid #E5E5E5;
                    }

                    >.item {
                        border-bottom: 1px solid #E5E5E5;
                    }

                    th.col {
                        font-size: 14px;
                        line-height: 17px;
                        color: $color-primary;
                        font-family: $font-secondary;
                        text-transform: uppercase;
                        font-weight: 400;
                        text-align: center;

                        &.item {
                            text-align: left;
                        }

                        &.qty {
                            text-align: center;
                        }
                    }

                    .item-info {
                        @media (max-width: 768px) {
                            .col.item{
                                padding: 15px 10px;
                            }
                        }
                        .product-item-name {
                            >a {
                                font-size: rem(16);
                                line-height: 17px;
                                color: $color-primary;
                                text-transform: none;
                                font-weight: 400;
                            }

                            +.item-options {
                                margin-top: 0px;
                            }
                        }

                        .item-options {
                            dt {
                                font-weight: 400;
                            }
                        }

                        .qty {
                            text-align: center;

                            .input-text {
                                border: none;
                                border-radius: 5px;
                                margin-top: 0;
                            }
                        }

                        .price {
                            text-align: center;

                            .cart-price {
                                .price {
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: $color-secondary;
                                    font-weight: 400;
                                }
                            }
                        }

                        .subtotal {
                            text-align: center;

                            .cart-price {
                                .price {
                                    font-size: 16px;
                                    line-height: 25px;
                                    font-weight: 600;
                                    color: $color-primary;
                                }
                            }
                        }

                        .category-name{
                            font-size: rem(14);
                            font-family: $font-secondary;
                            text-transform: uppercase;
                        }

                        .patient-data{
                            font-size: rem(14);
                            font-family: $font-secondary;

                            .type-service{
                                color: $color-primary;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .item-actions {
                        td {
                            padding: 0;

                            .actions-toolbar {
                                background: $color-gray-2;
                                padding: 10px 15px;
                                margin-bottom: 10px;
                                text-align: left;

                                .action-delete {
                                    float: right;
                                    background: none;
                                    border: none;
                                    font-size: 12px;
                                    line-height: 15px;
                                    color: $color-secondary;
                                    text-transform: uppercase;
                                    font-weight: 400;
                                    vertical-align: middle;
                                    margin: 0;
                                    padding: 0;

                                    &:hover {
                                        color: $color-primary;
                                    }

                                    span {
                                        vertical-align: middle;
                                    }

                                    &:before {
                                        content: "";
                                        width: 15px;
                                        height: 18px;
                                        display: inline-block;
                                        vertical-align: middle;
                                        background: url($img-path + "icons/icon-trash.svg");
                                        background-repeat: no-repeat;
                                        background-position: center center;
                                        background-size: contain;
                                        margin-right: 5px;
                                    }
                                }

                                .action-edit {
                                    background: none;
                                    border: none;
                                    font-size: 12px;
                                    line-height: 15px;
                                    color: $color-secondary;
                                    text-transform: uppercase;
                                    font-weight: 400;
                                    vertical-align: middle;
                                    margin: 0;
                                    padding: 0;

                                    &:hover {
                                        color: $color-primary;
                                    }

                                    span {
                                        vertical-align: middle;
                                    }

                                    &:before {
                                        content: "";
                                        width: 15px;
                                        height: 18px;
                                        display: inline-block;
                                        vertical-align: middle;
                                        background: url($img-path + "icons/icon-pen.svg");
                                        background-repeat: no-repeat;
                                        background-position: center center;
                                        background-size: contain;
                                        margin-right: 5px;
                                    }
                                }

                                .action.towishlist {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cart-discount {
        width: 24%;
        float: right;
        border: 0;
        box-sizing: border-box;
        background: $color-white;
        padding: 20px 25px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0,0.05);
        border-radius: 10px;
        margin-bottom: 30px;

        @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
            padding: 15px;
            float: none;
        }

        .block {
            .title {
                margin-bottom: 15px;

                strong {
                    font-size: rem(24);
                    color: $color-primary;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-family: $font-secondary;

                    @media (max-width: 768px) {
                        font-size: rem(19);
                    }

                    &:after {
                        display: none;
                    }
                }
            }

            .fieldset{
                margin: 0;
            }

            .field {
                display: block;
                margin: 0;

                .control {
                    width: 100%;
                }
            }

            .actions-toolbar {
                display: block;
                margin-top: 20px;

                .primary{
                    float: none;
                    display: block;

                    .action {
                        background: $color-white;
                        color: $color-primary;
                        font-size: rem(16);
                        border-color: $color-primary;
                        padding: 0px 20px;
                        margin: 0;
                        height: 40px;
                        display: block;
                        width: 100%;

                        &:before {
                            content: "";
                            width: 26px;
                            height: 26px;
                            display: inline-block;
                            vertical-align: middle;
                            background-image: url($img-path + "icons/icon-cupom.svg");
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: contain;
                            margin-right: 5px;
                        }

                        &:hover {
                            background-color: $color-primary;
                            color: $color-white;
                            border-color: $color-primary;

                            &:before{
                                background-image: url($img-path + "icons/icon-cupom-white.svg");
                            }
                        }
                    }
                }
            }
        }
    }

    .block.shipping {
        width: 34%;
        float: left;
        border: 0;
        box-sizing: border-box;
        background: $color-white;
        padding: 20px 30px;
        display: none;

         @media (max-width: 768px) {
             width: 100%;
             padding: 15px;
             float: none;
         }

        .title {
            margin-bottom: 15px;

            strong {
                font-size: rem(24);
                color: $color-primary;
                text-transform: uppercase;
                font-weight: 500;

                 @media (max-width: 768px) {
                     font-size: rem(19);
                 }

                &:after {
                    display: none;
                }
            }
        }

        .fieldset {
            display: inline-block;
            margin: 0;

            .legend,
            .note, br {
                display: none;
            }

            .control{
                width: 100%;

                .input-text{
                    margin-bottom: 10px;
                }
            }

            &.rate{
                .items.methods{
                    .item-title{
                        span{
                            font-weight: 400;
                            font-size: 15px;
                        }
                    }

                    .item-options{
                         font-size: 14px;
                    }
                }
            }
        }
    }

    .cart-summary {
        background: $color-white;
        padding: 30px;
        width: 24%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        position: unset!important;

        @media (max-width: 768px) {
           padding: 15px;
           width: 100%;
        }

        >.title {
            color: $color-primary;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 500;
            font-family: $font-secondary;
            line-height: 30px;
            margin: 0 0 15px;
        }

        .cart-totals {
            border: none;

            table {
                tr {
                    th {
                        font-size: rem(13);
                        text-transform: uppercase;
                    }

                    td {
                        font-size: rem(14);
                        text-transform: uppercase;

                        span{
                            text-decoration: none;
                        }
                    }

                    &.totals.sub{
                        th {
                            span {
                                color: #888888;
                            }
                        }

                        td {
                            span {
                                color: #888888;
                                text-decoration: none;
                            }
                        }
                    }

                    &.totals.shipping{
                        th {
                            span {
                                color: #888888;
                            }
                        }

                        td {
                            span {
                                color: #888888;
                                text-decoration: none;
                            }
                        }
                    }

                    &.totals{
                        th {
                            span {
                                color: #ff0000;
                            }
                        }

                        td {
                            span {
                                color: #ff0000;
                                text-decoration: line-through;
                            }
                        }
                    }

                    &.grand.totals {
                        border-top: 1px solid #E5E5E5;

                        th {
                            padding: 10px 5px;
                            margin: 0;
                            vertical-align: middle;

                            strong {
                                color: $color-secondary;
                                font-weight: 700;
                            }
                        }

                        td {
                            padding: 10px 5px;

                            strong {
                                color: $color-secondary;

                                span {
                                    color: $color-secondary;
                                    text-decoration: none;
                                }
                            }

                        }
                    }
                }
            }
        }

        .checkout-methods-items {
            .action.primary {
                font-size: rem(16);
                padding: 10px 20px;
                line-height: 1.6rem;

                &:before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    background-image: url($img-path + "icons/icon-check-white.svg");
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    margin-right: 5px;
                }
            }
        }
    }
}
