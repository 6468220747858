button {
    background: $color-primary;
    border: 2px solid $color-primary;
    border-radius: 7px;
    color: $color-white;
    font-family: $font-secondary;
    font-size: rem(16);
    font-weight: 500;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding: 3px 20px;

    &:hover {
        color: $color-white;
        background-color: lighten($color-primary, 9%);
        border: 2px solid lighten($color-primary, 9%);
    }

    &:focus {
        color: $color-white;
        background-color: lighten($color-primary, 9%);
        border: 2px solid lighten($color-primary, 9%);
    }

    &.action {
        &.primary {
            font-family: $font-secondary;
            font-size: rem(16);
            font-weight: 400;
            border-radius: 7px;
            background: $color-primary;
            border: 2px solid $color-primary;
            padding: 6px 20px;

            &:hover, &:focus {
                color: $color-white;
                background-color: lighten($color-primary, 9%);
                border: 2px solid lighten($color-primary, 9%);
            }
        }
    }
}

a.action.primary{
    border-radius: 7px;
}

.action {
    &.primary {
         font-family: $font-secondary;
         font-size: rem(16);
         font-weight: 400;
         border-radius: 7px;
         background: $color-primary;
         border: 2px solid $color-primary;
         padding: 6px 20px;
         line-height: 1.6rem;
         text-align: center;

        &:hover,
        &:focus {
             color: $color-white;
             background-color: lighten($color-primary, 9%);
             border: 2px solid lighten($color-primary, 9%);
        }

        &:disabled{
            background: $color-secondary;
            border-color: $color-secondary;
        }

        &.create {
            background: transparent;
            border: 2px solid $color-primary;
            color: $color-primary;
            padding: 6px 20px;
            border-radius: 7px;
            line-height: 1.6rem;

            &:hover {
                color: $color-white;
                background-color: $color-primary;
            }
        }
    }

    &.primary-outline {
        font-family: $font-secondary;
        font-size: rem(16);
        font-weight: 400;
        border-radius: 7px;
        background: $color-white;
        color: $color-4;
        border: 2px solid $color-4;
        padding: 6px 20px;
        line-height: 1.6rem;
        text-align: center;

       &:hover,
       &:focus {
            color: $color-white;
            background-color: lighten($color-4, 9%);
            border: 2px solid lighten($color-4, 9%);
            text-decoration: none;
       }

       &:disabled, &.disabled{
           opacity: 0.7;
       }
    }

    &.primary-outline-red {
        font-family: $font-secondary;
        font-size: rem(16);
        font-weight: 400;
        border-radius: 7px;
        background: $color-white;
        color: $color-primary;
        border: 2px solid $color-primary;
        padding: 6px 20px;
        line-height: 1.6rem;

       &:hover,
       &:focus {
            color: $color-white;
            background-color: lighten($color-primary, 9%);
            border: 2px solid lighten($color-primary, 9%);
            text-decoration: none;
       }

       &:disabled, &.disabled{
           opacity: 0.7;
       }
    }
}

a.btn-whatsapp-red{
    border: 2px solid $color-primary;
    border-radius: 7px;
    color: $color-primary;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 14px;
    font-family: $font-secondary;
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
    display: inline-block;
    margin-left: 10px;
    position: relative;
    background-color: $color-white;

    @media (max-width: 768px) {
        display: block;
        max-width: 105px;
        margin: 0 auto;
    }

    &:before{
        content: "";
        background: url($img-path + "icons/icon-whatsapp.svg") no-repeat 0 0;
        background-size: contain;
        display: inline-block;
        width: 17px;
        height: 17px;
        margin-right: 8px;
        position: relative;
        top: 4px;
    }

    &:hover{
        color: $color-white;
        text-decoration: none;
        background-color: $color-primary;

        &:before{
            background: url($img-path + "icons/icon-whatsapp-white.svg") no-repeat 0 0;
        }
    }
}
