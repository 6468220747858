.swatch-attribute {
    .swatch-attribute-label {
        text-transform: uppercase;
        font-weight: 400;
        font-size: rem(13);
        line-height: 16px;
    }

    .swatch-attribute-selected-option {
        text-transform: uppercase;
        font-weight: 400;
        font-size: rem(13);
        line-height: 16px;
    }

    .swatch-option {
        min-width: 20px;

        &.selected {
            outline: 2px solid $color-secondary;
            color: $color-primary;
        }

        &:hover {
            outline: 2px solid $color-secondary;
        }

        &.color {
            &:not(.disabled) {
                &:hover {
                    outline: 2px solid $color-secondary;
                }
            }
        }
    }

    &.color {
        .swatch-option {
            border: 1px solid #dadada;
            height: 18px;
            margin: 0 10px 5px 0;
            max-width: 100%;
            min-width: 18px;
            overflow: hidden;
            padding: 0;
        }
    }

    &.size {
        .swatch-option.text {
            background: $color-gray;
            color: $color-1;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            margin-right: 7px;
            min-width: 20px;
            padding: 3px 3px;
        }

        .swatch-option {
            &.selected {
                outline: 2px solid $color-secondary;
                color: $color-primary;
            }
        }
    }
}
