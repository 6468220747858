.product-info-main {
    .page-title-wrapper {
        .page-title {
            margin: 0 0 1.5rem;
            font-size: rem(24);
            color: $color-primary;
            text-transform: none;
            line-height: 1.3;
            text-align: center;

            &:after{
                content: "";
                background: $color-primary;
                width: 23px;
                height: 3px;
                display: block;
                margin: 10px auto;
            }
        }
    }

    .pricing {
        display: none;
    }

    .product.attribute.overview {
        font-weight: normal;
        font-size: rem(16);
        line-height: 25px;
        color: $color-secondary;
        margin: 0 0 30px;
        text-align: center;

        p {
            font-weight: normal;
            font-size: rem(16);
            line-height: 25px;
            color: $color-1;
        }
    }

    .price-box {
        text-align: center;
        margin: 20px 0;

        &.price-final_price{
            .price-wrapper {
                .price{
                   color: $color-primary;
                    font-size: rem(24);
                    font-weight: 500;
                    font-family: $font-secondary;
                }
            }
        }
        .normal-price {
            .price-label {
                color: $color-primary;
                font-size: rem(13);
                font-weight: 400;
            }

            .price-wrapper {
                .price {
                    color: $color-secondary;
                    font-size: rem(24);
                    font-weight: 600;
                }
            }
        }

        .special-price {
            display: inline-block;

            .price-label {
                color: $color-secondary;
                font-size: rem(14);
                font-weight: 400;
                text-transform: uppercase;

                &:after{
                    content: "";
                }
            }

            .price-wrapper {
                .price {
                    color: $color-primary;
                    font-size: rem(24);
                    font-weight: 500;
                }
            }
        }

        .old-price {
            text-decoration: none;
            display: block;

            .price-label {
                color: $color-secondary;
                font-size: rem(14);
                text-transform: uppercase;

                &:after{
                    content: "";
                }
            }

            .price-wrapper {
                .price {
                    color: $color-secondary;
                    font-size: rem(14);
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }

        .price-info {
            font-size: rem(13);
            display: inline-block;
        }

        .price-info-parcel {
            font-size: rem(14);
            color: $color-secondary;
            line-height: 20px;
            margin-bottom: 10px;
            text-transform: uppercase;
            font-family: $font-secondary;

            span {
                color: $color-primary;
            }

            p{
                font-size: rem(14);
                font-family: $font-secondary;
                margin: 0;
            }
        }
    }

    .box-tocart {
        margin: 20px 0 0;
        display: block;

        .fieldset{
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);

            @media (max-width: 768px) {
                @include flex-wrap(wrap);
            }
        }

        .actions {
            padding-top: 0;
        }

        .action {
            &.tocart {
                font-size: rem(18);
                padding: 4px 13px;
                font-weight: 400;

                &:before{
                    content: "";
                    background-image: get-icon('cart', $color-white);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 25px;
                    height: 25px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 5px;
                }

                span{
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }

        .field {
            &.qty {
                display: block;
                margin: 0;
                padding-right: 30px;
                min-height: auto;

                @media (max-width: 410px) {
                    padding-right: 0;
                    margin-bottom: 20px;
                }

                .label {
                    font-weight: 400;
                    font-size: rem(16);
                    color: $color-secondary;
                    text-transform: none;
                    display: inline-block;
                    padding: 0 7px 0 0;
                    margin: 0;
                    background: none;
                    vertical-align: top;
                }

                .control{
                    display: inline-block;

                    .input-group{
                        border: 2px solid $color-secondary;
                        border-radius: 7px;
                        height: 35px;

                        &__addon{
                            .input-group__button{
                                background: none;
                                border: none;
                                padding: 0 7px;
                                color: $color-secondary;
                                font-size: rem(15);
                                font-weight: 500;
                                font-family: $font-secondary;
                            }
                        }

                        &__input {
                            height: 35px;
                            width: 35px;
                            border: none;
                            color: $color-secondary;
                            font-size: rem(14);
                            font-weight: 500;
                            text-align: center;
                            font-family: $font-secondary;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .alert{
        max-width: 490px;
        margin: 0 auto 1rem;

        p{
            font-size: rem(12);
            line-height: 14px;
            text-align: center;
            letter-spacing: 0.03em;
            font-family: $font-secondary;
            margin: 0;
        }
    }

    .product-info-stock-sku {
        .product.attribute.sku {
            color: $color-1;
            font-size: rem(13);

            .type{
                font-weight: 400;
                margin-right: 1px;
            }
        }

        .stock.available {
            display: none;
        }
    }

    .product.attribute.sku-totvs{
        color: $color-1;
        font-size: rem(13);
        margin: 0 0 20px;

        .type {
            font-weight: 400;
            margin-right: 1px;
            display: inline-block;
        }

        .value{
            display: inline-block;
        }
    }

    .product-social-links {
        margin-bottom: 1.5rem;

        .product-addto-links {
            margin: 0;
            display: none;

            .towishlist {
                color: $color-primary;
                font-weight: 400;
                font-size: rem(14);
                text-transform: uppercase;
                display: inline-block;
                text-decoration: none;
                background: no-repeat;
                border: none;
                padding: 0;

                &:before {
                    content: '\e600';
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 28px;
                    line-height: 16px;
                    width: 28px;
                    color: inherit;
                    font-family: 'icons-blank-theme';
                    margin: -2px 5px 0 0;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                }
            }
        }

        .social-media-icons{
            span{
                display: block;
                font-size: rem(10);
                text-transform: uppercase;
                margin-bottom: 5px;
            }

            a{
                display: inline-block;
                vertical-align: middle;
                margin-right: 4px;

                &.icon-facebook, &.icon-whatsapp{
                    img{
                        width: 22px;
                        height: 22px;
                    }
                }

                &.icon-twitter{
                    img{
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }

    .product-options-wrapper {
        margin-top: 20px;
    }

    .health-insurance{
        background: $color-white;
        border-radius: 7px;
        padding: 30px;
        margin-bottom: 3rem;

        @media (max-width: 768px) {
            padding: 20px;
        }

        &__header{
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);

            @media (max-width: 410px) {
                @include flex-direction(column);
            }

            h3{
                color: $color-primary;
                font-weight: 500;
                font-size: rem(24);
                margin: 0 0 10px;
            }

            &-buttons{
                margin-bottom: 10px;

                .btn{
                    border: 2px solid $color-primary;
                    border-radius: 7px;
                    color: $color-primary;
                    font-weight: 500;
                    font-size: 0.75rem;
                    line-height: 14px;
                    font-family: $font-secondary;
                    height: 35px;
                    line-height: 35px;
                    padding: 0 20px;
                    display: inline-block;
                    margin-left: 13px;
                    position: relative;
                    background-color: $color-white;

                    @media (max-width: 768px) {
                        padding: 0 8px;
                        margin-left: 0;
                    }

                    &.btn-whatsapp{
                        &:before{
                            content: "";
                            background: url($img-path + "icons/icon-whatsapp.svg") no-repeat 0 0;
                            background-size: contain;
                            display: inline-block;
                            width: 17px;
                            height: 17px;
                            margin-right: 8px;
                            position: relative;
                            top: 4px;
                        }

                        &:hover{
                            &:before{
                                background: url($img-path + "icons/icon-whatsapp-white.svg") no-repeat 0 0;
                            }
                        }
                    }

                    &:hover{
                        color: $color-white;
                        text-decoration: none;
                        background-color: $color-primary;

                        &:before{
                            background: url($img-path + "icons/icon-whatsapp-white.svg") no-repeat 0 0;
                        }
                    }
                }
            }
        }

        p{
            font-size: rem(13);
            margin: 0;
        }
    }

    .result-info{
        text-align: center;
        position: relative;

        &:after{
            content: "";
            position: absolute;
            top: 13px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $color-8;
            z-index: -1;
        }

        span{
            display: inline-block;
            background: $color-primary;
            color: $color-white;
            font-size: rem(10);
            font-family: $font-secondary;
            text-transform: uppercase;
            border-radius: 3px;
            padding: 5px 7px;
            line-height: 1.2;
        }
    }
}

.product.media{
    .fotorama-item{
        .fotorama__nav.fotorama__nav--thumbs {
            .fotorama__nav__shaft{
                .fotorama__thumb-border{
                    border-color: $color-secondary;
                }
            }

            .fotorama__nav__frame{
                .fotorama__thumb{
                    margin: 0 3px;
                }
            }
        }
    }

    .fotorama-stretch, .fotorama-focus-overlay:after, .fotorama__stage__shaft, .fotorama__stage__frame, .fotorama__html, .fotorama__video iframe {
        height: 90%;
    }
}

.product {
    &.info.detailed {
        margin-bottom: 50px;
        float: right;
        width: 40%;
        clear: none;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .data {
        &.items {
            .item {
                &.title {
                    >.switch {
                        font-size: rem(16);
                        color: $color-secondary;
                        border-radius: 7px;
                        border: none;
                        background: $color-white;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
                        padding: 10px 20px;
                        height: auto;

                        .counter {
                            font-size: rem(13);

                            &:before {
                                content: '(';
                            }

                            &:after {
                                content: ')';
                            }
                        }
                    }

                    &:not(.disabled) {
                        >.switch {
                            &:hover {
                                font-size: rem(16);
                                color: $color-secondary;
                                border: none;
                                padding: 10px 20px;
                                background: $color-white;
                            }

                            &:active,
                            &:focus {
                                background: $color-3;
                                color: $color-primary;
                                padding: 10px 20px;
                            }
                        }
                    }

                    &.active {
                        >.switch {
                            background: $color-3;
                            color: $color-primary;
                            padding: 10px 20px;

                            &:hover {
                                font-size: rem(16);
                                color: $color-primary;
                                border: none;
                                background: $color-3;
                            }
                        }
                    }
                }

                &.content {
                    margin-top: 50px;
                    border: none;
                    background: $color-white;
                    border-radius: 10px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

                    @media (max-width: 768px){
                        margin-top: 15px;
                    }

                    .table-wrapper{
                        .additional-attributes{
                            tr{
                                th{
                                    font-size: rem(14);
                                }

                                td{
                                   font-size: rem(14);
                                }
                            }
                        }
                    }

                    .review-list {
                        .block-title {
                            strong {
                                color: $color-primary;
                                font-weight: 600;
                                line-height: 1.1;
                                font-size: rem(18);
                                margin-top: 2.5rem;
                                margin-bottom: 2rem;
                            }
                        }

                        .review-items {
                            .review-title {
                                font-size: rem(18);
                            }

                            .review-ratings {
                                .rating-summary {
                                    .rating-result {
                                        margin-top: -2px;

                                        span {
                                            &:before {
                                                color: $color-secondary;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .review-content {
                            font-size: rem(14);
                        }

                        .review-details {
                            p {
                                font-size: rem(14);
                            }
                        }
                    }
                }

                .review-add {
                    .review-form {
                        .review-legend {
                            color: $color-primary;
                            font-weight: 600;
                            line-height: 1.1;
                            font-size: rem(16);

                            span, strong{
                                display: inline-block;
                                margin-right: 5px;
                            }
                        }

                        .review-field-ratings {
                            .label {
                                display: inline-block;
                                float: none;
                                text-align: left;
                                width: auto;
                                padding: 0 0 5px;
                                font-weight: 500;
                            }

                            .control {
                                padding: 10px 0;

                                .review-field-rating {
                                    .review-control-vote {
                                        padding: 0px;
                                        margin: -3px 0 0 0;
                                        display: inline-block;

                                        label {
                                            &:before {
                                                color: $color-secondary;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .field {
                            .label {
                                font-weight: 400;
                            }
                        }

                        .action.submit.primary {
                            background: $color-primary;
                            color: $color-white;
                            border: 2px solid $color-primary;

                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .page-layout-1column {
        .product.media {
            width: 44%;
        }

        .product-info-main {
            width: 58%;
            float: left;
            padding-right: 2%;
        }
    }
}

.column.main{
    .block{
        &.upsell{
            .title{
                strong{
                    font-weight: 600;
                    font-size: rem(25);
                    line-height: 1.2;
                    color: $color-primary;
                    text-transform: uppercase;
                    position: relative;
                    margin: 0 0 3rem;

                    @media (max-width: 991px) {
                        font-size: rem(26);
                    }

                    &:after {
                        content: "";
                        width: 30px;
                        height: 5px;
                        background: $color-secondary;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        border-radius: 30px;
                    }
                }
            }

            .products.grid {
                .product-items {
                    .product-item{
                        min-height: 420px;
                    }
                }
            }

            .price-info,
            .price-info-parcel,
            .price-parcel-button {
                display: none;
            }
        }

        &.related{
            clear: both;
            margin-bottom: 1rem;

            .price-info,
            .price-info-parcel,
            .price-parcel-button {
                display: none;
            }

            .block-actions{
                display: none;
            }

            .products{
                .product{
                    .field.related{
                        label{
                            font-size: 13px;
                            color: #17386B;
                            text-transform: uppercase;
                        }
                    }

                    .product-item-actions {
                        margin: 0;
                        border-top: 1px solid #EBEBEB;
                        position: absolute;
                        bottom: 20px;
                        padding: 15px 0 0;
                        width: 85%;

                        .actions-primary {
                            @include flexbox;
                            @include justify-content(space-between);

                        }
                    }

                    .price-types{
                        @include flex-direction(column);
                    }
                }
            }
        }
    }
}

.price-types{
    @include flexbox;
    align-items: center;

    @media (max-width: 768px) {
        @include flex-wrap(wrap);
        @include flex-direction(column);
    }

    p{
        text-transform: uppercase;
        font-size: rem(10);
        color: $color-secondary;
        font-family: $font-secondary;
        display: inline-block;
        vertical-align: middle;
        margin: 0;

        span{
            text-transform: uppercase;
            font-size: rem(16);
            color: $color-primary;
            font-family: $font-secondary;
        }

        small{
            text-transform: uppercase;
            font-size: rem(10);
            color: $color-primary;
            font-family: $font-secondary;
        }
    }

    .discount{
        background: $color-5;
        color: $color-white;
        text-transform: uppercase;
        font-size: rem(10);
        border-radius: 2px;
        padding: 2px 4px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
    }

    .price-site{
        margin-right: 15px;
    }

    .price-local{
        margin-right: 15px;
    }

    .price-discount{
        margin-right: 15px;

        .discount{
            background: $color-6;
        }
    }
}
