html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-size-adjust: 100%;
}

body {
    font-family: $font-primary;
    font-size: $font-size;
    color: $color-secondary;
    background: $color-gray-2;
    font-weight: 400;
}

a, .alink {
    color: $color-primary;
    transition: $transition-links;

    &:hover {
        color: $color-secondary;
    }
}

a:focus,
button:focus {
    outline: none;
    box-shadow: none;
}

img {
    max-width: 100%;
    height: auto;
}


.whatsapp-button{
    position: fixed;
    bottom: 15px;
    left: 15px;


    @media (max-width: 768px){
        max-width: 50px;
        z-index: 5;
    }

    img{
        display: block;
    }
}
