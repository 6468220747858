.wrap-center{
    text-align: center;

    p{
        text-align: center;
        display: inline-block;
    }
}

.list-convenios-block{
    @include flexbox;
    flex-wrap: wrap;

    .item{
        width: 17%;
        padding: 5px 0;
        text-align: center;
        margin: 0px 15px;
        border-bottom: 1px solid #EBEBEB;

        @media (max-width: 768px) {
            width: 47%;
            margin: 0px 1%;
        }

        img{
            max-width: 115px;
        }
    }
}
