.page-layout-2columns-left,
.page-layout-1column{
    .breadcrumbs{
        margin: 20px auto;

        .items{
            font-size: rem(13);
            text-transform: uppercase;
            color: $color-7;
            font-family: $font-secondary;

            @media (max-width: 991px) {
                font-size: rem(12);
            }

            .item{
                a{
                    font-size: rem(13);
                    color: $color-7;

                    @media (max-width: 991px) {
                        font-size: rem(12);
                    }
                }
            }
        }
    }
}
