/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow {
    position: absolute;
    right: -30px;
    top: 22%;
    border-radius: 0px;
    width: 40px;
    height: 40px;
    font-size: 0px;
    padding: 0;
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    @media (max-width: 768px){
        right: -15px;
        width: 30px;
    }

    &:hover, &:active, &:focus{
        border: none;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }

    &.slick-prev {
        background-image: url($img-path + "icons/icon-arrow-left.svg");
        left: -30px;

        @media (max-width: 768px){
            left: -15px;
        }
    }

    &.slick-next {
        background-image: url($img-path + "icons/icon-arrow-right.svg");
    }
}

.slick-dots{
    margin: 40px auto 0;
    text-align: center;
    padding: 0;

    li{
        display: inline-block;
        margin: 0 6px;

        &.slick-active{
            button{
                opacity: 1;
                background: $color-primary;
            }
        }

        button{
            border-radius: 100%;
            width: 10px;
            height: 10px;
            background: $color-secondary;
            opacity: 0.25;
            font-size: 0px;
            border: none;
            padding: 0;
        }
    }
}
